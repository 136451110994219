// project imports
import _, { cloneDeep } from "lodash";
import { Navigate, RouteObject } from "react-router-dom";
import BXPage from "src/components/BXUI/Page";
import { PageAuthGuard } from "src/components/BXUI/PageAuth/PageAuthGuard";
import MainLayout from "src/layout/MainLayout";
import { BXApp, BXAppCollection, ILayout } from "src/types/BXAppType";
import AuthGuard from "src/utils/route-guard/AuthGuard";
import { AppBuilder } from "src/views/pages/BuildX/AppBuilder";
import { FormBuilderEditor } from "src/views/pages/BuildX/FormBuilder";

import { useBXContext } from "src/BXEngine/BXContext";
import { BXPageProvider } from "src/contexts/BXPageProvider";
import { RedirectGuard } from "src/utils/route-guard/RedirectGuard";
import { LayoutBuilder } from "src/views/pages/BuildX/FormBuilder/LayoutBuilder";
import { PageBuilder } from "src/views/pages/BuildX/FormBuilder/PageBuilder";
import { ManageComponents } from "src/views/pages/BuildX/ManageComponents";
import { ManageEnv } from "src/views/pages/BuildX/ManageEnv";
import { ManageMedia } from "src/views/pages/BuildX/ManageMedia";
import { ManageOAS } from "src/views/pages/BuildX/ManageOAS";
import { ManagePolicies } from "src/views/pages/BuildX/ManagePolicies";
import { ManageRoles } from "src/views/pages/BuildX/ManageRoles";
import { ManageTemplates } from "src/views/pages/BuildX/ManageTemplates";
import { ManageUsers } from "src/views/pages/BuildX/ManageUsers";
import { Migrations } from "src/views/pages/BuildX/Migrations";
import { BXEngine } from "../BXEngine";
import { BXBuilderContextProvider } from "../BXEngine/BXBuilderContext";

// ==============================|| MAIN ROUTING ||============================== //

// if app has no pages at all, then we need to create layout for pageless app to say that there is no page for this app
const MainRoutes = (bxApps: BXApp[], currentApp?: BXApp, isLoggedIn?: boolean, loadingApps?: boolean) => {
  const { fqdnApp, unprotectedPages, appRoutesMap } = useBXContext();
  const currentBXApps: BXApp[] = bxApps.length > 0 ? bxApps : [fqdnApp as BXApp];

  const routes: RouteObject[] = currentBXApps.map(app => {
    let _templateConfig:
      | {
          icon?: string;
          logo?: string;
          collections?: BXAppCollection[];
          appVersion?: string;
          layouts?: ILayout[];
          [key: string]: any;
        }
      | undefined;

    if (app?.templateConfig) {
      _templateConfig = cloneDeep(app.templateConfig);
    } else {
      _templateConfig = cloneDeep(app?.upTemplateConfig);
    }

    const result = {
      breadcrumbs: true,
      path: fqdnApp ? "/" : `/${app?.slug}`,
      children: _.flatten(
        _templateConfig?.collections?.map(collection =>
          collection.pages.map(page => {
            const pageElement = (
              <PageAuthGuard app={app} page={page}>
                <BXPageProvider key={page.id} pageId={page?.id}>
                  <BXPage path={[app.name, collection.name, page.name].join(".")} page={page} layouts={_templateConfig?.layouts}>
                    {page.layout === "custom" ? (
                      <BXEngine
                        page={page}
                        auth={{}}
                        key={page?.id}
                        isVisible
                        layout={[page as any]}
                        path={[app.name, collection.name, page.name].join(".")}
                        disablePageDirty
                        isPageView
                      />
                    ) : (
                      page?.views
                        ?.filter(view => view.info?.visibility !== "Hidden")
                        ?.map?.(view => (
                          <BXEngine
                            page={page}
                            auth={{}}
                            key={view?.id}
                            layout={[view]}
                            path={[app.name, collection.name, page.name, view.info?.name].join(".")}
                          />
                        ))
                    )}
                  </BXPage>
                </BXPageProvider>
              </PageAuthGuard>
            );

            const routePrefix =
              collection?.slug === "/" ? (fqdnApp ? "/" : `/${app?.slug}`) : `${collection?.slug?.split("/").filter(Boolean).join("/")}/`;

            const pathPrefixKey = fqdnApp ? "/" : `${app?.slug}`;
            const collectionKey = collection?.slug === "/" ? null : collection?.slug?.split("/").filter(Boolean).join("/");
            const pageKey = page.slug;
            const finalKey = collectionKey === null ? pageKey : `/${collectionKey}${pageKey}`;

            const routeKey = `${pathPrefixKey}${finalKey}`.replace(/\/+/g, "/");

            if (page?.unprotectedPage) {
              unprotectedPages.current.add(routeKey);
              const pagePath: string = [app?.name, collection?.name, page?.name].join(".");
              unprotectedPages.current.add(pagePath);
            }

            appRoutesMap.current[routeKey] = {
              isRootCollection: collection?.slug === "/",
            };

            const shouldRedirect = page?.shouldRedirectAuthenticatedUser;
            const isUnprotectedPage = page?.unprotectedPage;

            const landingPageUri = app?.appConfig?.pageUri;

            const finalPageElement = (() => {
              if (shouldRedirect) {
                return isUnprotectedPage ? (
                  <RedirectGuard protectedLandingPage={landingPageUri}>{pageElement}</RedirectGuard>
                ) : (
                  <AuthGuard>
                    <RedirectGuard>{pageElement}</RedirectGuard>
                  </AuthGuard>
                );
              } else {
                return isUnprotectedPage ? pageElement : <AuthGuard>{pageElement}</AuthGuard>;
              }
            })();

            return {
              id: page.name,
              breadcrumbs: true,
              path: `${routePrefix}${page.slug}`.replaceAll(/\/+/g, "/"),
              element: finalPageElement,
            };
          })
        )
      ),
    };
    return result;
  });

  // Unprotected Fallback route
  if (
    ((!loadingApps && currentApp?.templateConfig && !currentApp?.templateConfig?.collections?.length) ||
      currentApp?.templateConfig?.collections ||
      (!loadingApps && fqdnApp?.upTemplateConfig)) &&
    !isLoggedIn
  ) {
    routes.push({
      path: "*",
      element: <Navigate to={`${currentApp?.appConfig?.unprotectedPageUri || fqdnApp?.appConfig?.unprotectedPageUri || "/"}`} />,
    });
  }
  //Protected Fallback
  if (
    ((!loadingApps && currentApp?.templateConfig && !currentApp?.templateConfig?.collections?.length) ||
      currentApp?.templateConfig?.collections ||
      (!loadingApps && fqdnApp)) &&
    isLoggedIn
  ) {
    routes.push({
      path: "*",
      element: <Navigate to={`${currentApp?.appConfig?.pageUri || "/"}`} />,
    });
  }

  // if (
  //   (!loadingApps && currentApp?.templateConfig && !currentApp?.templateConfig?.collections?.length) ||
  //   currentApp?.templateConfig?.collections ||
  //   (!loadingApps && fqdnApp)
  // ) {
  //   routes.push({
  //     path: "*",
  //     element: <Navigate to={"/"} />,
  //   });
  // }

  routes.push({
    path: "/buildx/app",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <AppBuilder />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });
  routes.push({
    path: "/buildx/users",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <ManageUsers />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });
  routes.push({
    path: "/buildx/policies",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <ManagePolicies />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });
  routes.push({
    path: "/buildx/roles",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <ManageRoles />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });
  routes.push({
    path: "/buildx/media",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <ManageMedia />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });
  routes.push({
    path: "/buildx/oas",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <ManageOAS />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });
  routes.push({
    path: "/buildx/env",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <ManageEnv />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });

  routes.push({
    path: "/buildx/component",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <ManageComponents />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });

  routes.push({
    path: "/buildx/templates",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <ManageTemplates />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });

  routes.push({
    path: "/buildx/migrations",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <Migrations />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });

  routes.push({
    path: "/buildx/form-builder/:appId/:collectionId/:pageId/:viewId",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <FormBuilderEditor />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });

  routes.push({
    path: "/buildx/page-builder/:appId/:collectionId/:pageId",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <PageBuilder />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });

  routes.push({
    path: "/buildx/form-builder/template/:templateId",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <FormBuilderEditor />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });
  routes.push({
    path: "/buildx/form-builder/template/history/:historyId",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <FormBuilderEditor />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });
  routes.push({
    path: "/buildx/form-builder/layout/:appId/:layoutId",
    element: (
      <AuthGuard forbid={["USER"]}>
        <BXBuilderContextProvider>
          <LayoutBuilder />
        </BXBuilderContextProvider>
      </AuthGuard>
    ),
  });

  return [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout navigation={routes} currentApp={currentApp ?? fqdnApp} fqdnApp={fqdnApp} />
        </AuthGuard>
      ),
      children: [...routes],
    },
  ];
};

export default MainRoutes;
