import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  DialogActions,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import _, { isNil } from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { RHFIconPicker } from "src/components/BXUI/FormControls/RHFIconPicker";
import { BXIcon } from "src/components/BXUI/Icon";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { BXPageType, PageLayout } from "src/types/BXPageType";
import { UIElement } from "src/types/UIElement";
import { v4 } from "uuid";
import * as yup from "yup";
import { handleSlugChange } from "../../FormBuilder/utils";
import { visibilityData } from "../components/ViewInfo";

type CreatePageFormProps = {
  onSave: (data: any, closeOnSuccess?: boolean) => Promise<any>;
  onCancel: Function;
  page?: BXPageType;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  layouts?: any[];
  params?: {
    appId?: string;
    collectionId?: string;
  };
};

const schema = yup
  .object({
    title: yup.string().required(),
    slug: yup.string().required(),
    name: yup.string().required(),
    icon: yup.string(),
    inProfileMenu: yup.boolean(),
    layout: yup.string().required(),
    hidePageTitle: yup.boolean(),
    unprotectedPage: yup.boolean(),
    shouldRedirectAuthenticatedUser: yup.boolean(),
  })
  .required();

const layoutOptions: { value: PageLayout; label: string; icon: string }[] = [
  { value: "default", label: "Default", icon: "BrandPagekit" },
  { value: "stack", label: "Single", icon: "Stack2" },
  { value: "switch", label: "Switch", icon: "Stack" },
  { value: "custom", label: "Custom", icon: "Layout" },
];

export const CreatePageForm: FC<CreatePageFormProps> = ({ onCancel = _.noop, params, onSave = _.noop, page, layouts, setIsDirty }) => {
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, dirtyFields },
    setValue,
    watch,
    getValues,
  } = useForm<FieldValues>({
    defaultValues: {
      ...page,
      layout: page?.layout || "default",
      hidePageTitle: page?.hidePageTitle !== undefined ? page.hidePageTitle : false,
      info: { ...page?.info, visibility: page?.info?.visibility || "Visible" },
      unprotectedPage: page?.unprotectedPage ?? false,
      shouldRedirectAuthenticatedUser: page?.shouldRedirectAuthenticatedUser ?? false,
    },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  useCallbackPrompt(isDirty);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  const [layoutValue, setLayoutValue] = useState<PageLayout | null>(getValues("layout") || null);
  const navigate = useNavigate();
  const prevPageNameRef = useRef(watch("name") || "");

  return (
    <Box component='form' noValidate autoComplete='off'>
      <Container maxWidth={"sm"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BXInput
              name={"title"}
              control={control}
              label='Page Title'
              variant='outlined'
              id={"bx-page-create-title-input"}
              error={errors.title}
            />
          </Grid>
          <Grid item xs={12}>
            <BXInput
              name={"name"}
              control={control}
              label='Name'
              variant='outlined'
              id={"bx-page-create-name-input"}
              onChange={e => handleSlugChange(e.target.value, prevPageNameRef, getValues, setValue)}
              error={errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <BXInput
              name={"slug"}
              helperText={"/app/collection/page"}
              control={control}
              label='Slug'
              variant='outlined'
              id={"bx-view-create-slug-input"}
              error={errors.slug}
            />
          </Grid>
          {!!layouts?.length && (
            <Grid item xs={12}>
              <Controller
                control={control}
                name={"layoutId"}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    disablePortal
                    id='app-layouts'
                    fullWidth
                    defaultValue={layouts?.find(layout => layout?.id == value)}
                    options={layouts}
                    onChange={(event: any, newValue: any) => {
                      onChange(newValue?.id);
                    }}
                    getOptionLabel={item => item?.name}
                    renderInput={params => <TextField {...params} label='Layout' fullWidth variant='outlined' />}
                    componentsProps={{
                      clearIndicator: {
                        sx: {
                          marginRight: "7px",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <BXInput control={control} name={"info.visibility"} select fullWidth label={"Visibility"}>
              {visibilityData?.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </BXInput>
          </Grid>

          <Grid item xs={12}>
            <RHFIconPicker
              label={"Icon"}
              control={control}
              name={"iconConfig.icon"}
              objName={`iconConfig`}
              id={"bx-page-create-icon-input"}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={6}>
            <BXSwitch control={control} name={"hidePageTitle"} label={"Hide Page Title"} />
          </Grid>
          <Grid item xs={6}>
            <BXSwitch control={control} name={"unprotectedPage"} label={"Unprotected Page"} />
          </Grid>
          {watch(`unprotectedPage`) && (
            <Grid item xs={12}>
              <BXSwitch
                control={control}
                name={"shouldRedirectAuthenticatedUser"}
                label={"Redirect authenticated user to logged in landing page"}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormLabel style={{ marginBottom: 12, marginTop: 12 }}>Layout</FormLabel>
            <Box display={"flex"} justifyContent={"space-between"}>
              <ToggleButtonGroup
                value={layoutValue || getValues("layout")}
                exclusive
                onChange={(e, value) => {
                  setLayoutValue(value);
                  setValue("layout", value);
                  if (value === "custom") {
                    setValue("pageLayoutContainer", {
                      id: v4(),
                      type: "FlexContainer",
                      children: page?.views.map(view => {
                        const id = v4();
                        // @ts-ignore
                        return { type: "viewRef", $refId: view.id, id, props: { id, key: id } } as UIElement;
                      }),
                      dataSource: {
                        sourceType: "NONE",
                        columnCount: "3",
                        userInputs: [],
                        simple: "{\n}",
                      },
                    } as UIElement);
                  }
                }}
                aria-label='text alignment'
              >
                {layoutOptions.map(option => (
                  <ToggleButton
                    key={option.value}
                    value={option.value}
                    aria-label='left aligned'
                    style={{ display: "flex", flexDirection: "column", padding: 12 }}
                  >
                    <BXIcon icon={option.icon} />
                    {option.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>

              {layoutValue === "custom" && !isNil(page?.id) ? (
                <>
                  <Button
                    onClick={() => {
                      handleSubmit(values => onSave(values, false))().then(() => {
                        const appId = params?.appId;
                        const collectionId = params?.collectionId;
                        const pageId = page?.id;
                        navigate(`/buildx/page-builder/${appId}/${collectionId}/${pageId}/`);
                      });
                    }}
                  >
                    Open Page Builder
                  </Button>
                </>
              ) : layoutValue === "custom" ? (
                <p>Save the page first to be able to edit the page custom layout</p>
              ) : null}
            </Box>
          </FormControl>
        </Grid>
      </Container>
      <Grid item xs={12}>
        <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
          <Button onClick={handleSubmit(onSave)} variant={"contained"} aria-label={"save"}>
            Save
          </Button>
        </DialogActions>
      </Grid>
    </Box>
  );
};
