import { FormHelperText } from "@mui/material";
import { CardCvcElement } from "@stripe/react-stripe-js";
import { StripeCardNumberElementOptions } from "@stripe/stripe-js";
import { memo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useBXContext } from "src/BXEngine/BXContext";

export const StripeCardCvcElement = memo((props: any) => {
  const { stripeVariables } = props;
  const { clientSecrets, stripePromises } = useBXContext();
  const [error, setError] = useState<any>(null);
  const location = useLocation();
  const builderView = location.pathname.startsWith("/buildx/form-builder");
  const parentId = builderView ? props?.$config?.parent?.id : props?.__config?.parent?.id;
  const matchingClientSecret = clientSecrets?.find(clientSecret => clientSecret.id === parentId);
  const matchingStripePromise = stripePromises?.find(stripePromise => stripePromise.id === parentId);

  const CARD_ELEMENT_OPTIONS: StripeCardNumberElementOptions = {
    style: {
      base: stripeVariables,
    },
    placeholder: "Security code",
  };

  return (
    <>
      {matchingClientSecret && matchingStripePromise && (
        <CardCvcElement
          onChange={(e: any) => {
            setError(e.error);
          }}
          options={CARD_ELEMENT_OPTIONS}
        />
      )}
      <FormHelperText error>{error?.message}</FormHelperText>
    </>
  );
});
