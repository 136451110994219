import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

interface RedirectGuardProps {
  children: React.ReactNode;
  protectedLandingPage?: string;
}
/**
 * Redirection guard middleware for pages that should send user to landing page if logged in
 */
export const RedirectGuard: FC<RedirectGuardProps> = ({ children, protectedLandingPage }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  if (isLoggedIn) {
    navigate(`${protectedLandingPage ?? "/"}`, { replace: true });
  }

  return <>{children}</>;
};
