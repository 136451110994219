import { useEffect, useRef } from "react";
import { useAppTrigger } from "src/features/appState/selectors";
import { handleLoad } from "./RenderItems";

const OnTriggerHandler = ({ element, actionHandlerMapper, pageId, viewName, viewsState, viewKey, refetchDataSource, fetchNextPage }) => {
  const hasLoaded = useRef(false);
  const trigger = useAppTrigger(viewKey);

  useEffect(() => {
    // Perform an action when the event/signal/trigger is triggered and brooked
    const handleTrigger = async () => {
      if (trigger?.type === "refetch") {
        refetchDataSource?.();
      }

      if (trigger?.type === "fetchNextPage") {
        const cursor = trigger?.payload?.currentCursorParam ?? null;
        await fetchNextPage?.(cursor, true);

        trigger?.payload?.resolver?.();
      }
      if (trigger?.type === "onLoad" && !hasLoaded.current) {
        handleLoad(element, actionHandlerMapper, pageId, viewName, viewsState);
        hasLoaded.current = true;
      }
    };

    handleTrigger();
  }, [trigger, viewKey]);

  return null;
};

export default OnTriggerHandler;
