import axios from "axios";

const extAxiosServices = axios.create({
  timeout: 10000,
});

extAxiosServices.defaults.headers.common.Authorization = "Bearer " + localStorage.getItem("accessToken");
extAxiosServices.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
extAxiosServices.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(
      (error?.response && error?.response?.data && { requestStatusCode: error?.request?.status, ...error.response.data }) ||
        "Wrong Services"
    );
  }
);
export default extAxiosServices;
