import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { IconCopy } from "@tabler/icons-react";
import _ from "lodash";
import React, { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBXContext } from "src/BXEngine/BXContext";
import { ItemActions } from "src/components/BXUI/DataTable/ItemActions";
import { BXDataTableColumn } from "src/components/BXUI/DataTable/index";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { formatDate } from "src/utils/generalUtils";
import { useReplaceDataPlaceholders } from "./ActionButton";
import CellValue from "./CellValue";

const useStyles = makeStyles((theme: Theme) => ({
  videoMedia: {
    height: "120px",
    border: `0.5px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.common.black}`,
    [theme.breakpoints.down("lg")]: {
      height: "80px",
    },
  },
  root: {
    "&:hover": {
      "& $copyContainer": { display: "block" },
    },
  },
  copyContainer: {
    display: "none",
  },
}));

export const TableItem: FC<{
  item: any;
  auth: any;
  columns: BXDataTableColumn[];
  actions?: any[];
  isSelected?: boolean;
  userInputsValues?: { [inputName: string]: any };
  isUserInput?: boolean;
  views: any;
  tableId: any;
  onSelectRow?: (object: any) => void;
  handleDragHover?: any;
  __data?: any;
  closeModal?: any;
  parentIds?: any;
  index?: any;
  order?: any;
  pageId?: any;
  handleUpdateElement?: any;
  queryKeys?: any[];
  viewName?: string;
  selectedRows?: any[];
  setSelectedRows: any;
  isSelectAllChecked: boolean;
  path?: string;
  info?: {
    name?: string;
    showApiMode?: string;
    paginationMode?: string;
    enableMultipleSelection?: string;
    viewName?: string;
  };
}> = ({
  item,
  columns,
  actions,
  auth,
  onSelectRow,
  userInputsValues,
  isUserInput,
  views,
  tableId,
  __data,
  closeModal,
  parentIds,
  queryKeys,
  pageId,
  viewName,
  info,
  selectedRows,
  setSelectedRows,
  path,
}) => {
  const classes = useStyles();
  const { viewsState, currentApp, fqdnApp } = useBXContext();
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders({ viewName: info?.viewName });
  const [state, setState] = useState("normal");
  const isDisabled = () => state === "deleted";
  const isLoading = () => state === "loading";
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getCellValue = useCallback(
    (item: any, col: any) => {
      const { source, type, hyperLinkPlaceholder } = col || {};
      const value = source;

      if (!value) return "";
      let result = replaceDataPlaceholders({
        queryString: value,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        fallback: "",
      });
      const typeIsDate = type === "Date Time";

      if (_.isObject(result)) {
        result = JSON.stringify(result);
      } else if (typeIsDate) result = formatDate(result, col?.dateFormat, "", col?.isUserLocalTime);

      return result;
    },
    [isUserInput, userInputsValues]
  );
  const CheckTooltip = useCallback(
    (props: any) => {
      const { children, col } = props;
      if (
        !(
          col?.type != "Boolean" &&
          !col?.allowWrap &&
          col?.type != "Image" &&
          col?.type != "Video" &&
          col?.type != "Audio" &&
          col?.type != "View Builder" &&
          getCellValue(item, col)
        )
      )
        return <>{children}</>;
      return <Tooltip title={getCellValue(item, col)}>{children}</Tooltip>;
    },
    [getCellValue, item]
  );

  const handleCheckboxChange = (rowId: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setSelectedRows((prevSelectedRows: number[]) => {
      if (isChecked) {
        if (!prevSelectedRows.includes(rowId)) {
          return [...prevSelectedRows, rowId];
        }
      } else {
        return prevSelectedRows.filter(id => id !== rowId);
      }
      return prevSelectedRows;
    });
  };

  const handleLinkClick = index => {
    const linkUrlValue =
      replaceDataPlaceholders({
        queryString: columns[index]?.linkUrl,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
      }) || "";

    if (linkUrlValue) {
      if (linkUrlValue.startsWith("/")) {
        const pathPrefix = fqdnApp ? "" : currentApp?.slug;
        navigate(`${pathPrefix}${linkUrlValue}`);
      } else {
        window.open(linkUrlValue, columns[index]?.openLinkAs || "_blank");
      }
    } else {
      enqueueSnackbarRef("Invalid URL!", { variant: "error" });
    }
  };

  return (
    <React.Fragment>
      {info?.enableMultipleSelection && (
        <Checkbox
          sx={{
            margin: "13px 5px",
          }}
          color='primary'
          checked={selectedRows?.includes(item?.__id)}
          onChange={handleCheckboxChange(item?.__id)}
          onClick={e => {
            e.stopPropagation();
          }}
        />
      )}

      {columns.map((col, index) => (
        <PermissibleRender isAllowed action={["VIEW"]} path={[path, col.name].join(".")}>
          {({ permitted }) =>
            permitted && (
              <React.Fragment key={col?.name + index}>
                {col?.type != "Actions" ? (
                  // <CheckTooltip col={col}>
                  <TableCell
                    align={(col?.alignColumn as any) ?? "left"}
                    sx={{
                      position: "relative",
                      ...(!col?.allowWrap && { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }),
                      cursor: "pointer",
                      minWidth: col?.type == "Video" || col?.type == "Audio" ? 300 : undefined,
                      color: col?.type == "Link" ? "#6060ff" : "inherit",
                    }}
                    className={classes.root}
                    onClick={() => {
                      if (col?.type == "Link") {
                        handleLinkClick(index);
                      }
                    }}
                  >
                    {col?.allowCopy &&
                      col?.type != "Boolean" &&
                      col?.type != "Image" &&
                      col?.type != "Video" &&
                      col?.type != "Audio" &&
                      col?.type != "HyperLink" &&
                      !!getCellValue(item, col) && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: "35%",
                            left: 0,
                          }}
                          onClick={() => {
                            enqueueSnackbarRef?.("Copied!", {
                              variant: "success",
                            });
                            navigator.clipboard.writeText(getCellValue(item, col));
                          }}
                          className={classes.copyContainer}
                        >
                          <IconCopy />
                        </Box>
                      )}

                    <CellValue
                      __data={__data}
                      path={path}
                      item={item}
                      index={index}
                      pageId={pageId}
                      views={views}
                      col={col}
                      value={getCellValue(item, col)}
                    />
                  </TableCell>
                ) : (
                  // </CheckTooltip>
                  col?.type == "Actions" &&
                  !_.isEmpty(actions?.filter(action => !action.isGlobal)) && (
                    <TableCell>
                      <DialogActions
                        style={{ width: "100%", padding: 0, display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                      >
                        <ItemActions
                          item={item}
                          path={path}
                          queryKeys={queryKeys}
                          viewName={viewName}
                          onSelectRow={onSelectRow}
                          disabled={isDisabled() || isLoading()}
                          onStateChange={newState => setState(newState)}
                          actions={actions?.filter?.(action => !action.isGlobal)}
                          isUserInput={isUserInput}
                          views={views}
                          tableId={tableId}
                          __data={__data}
                          closeModal={closeModal}
                          pageId={pageId}
                          parentIds={parentIds}
                        />
                      </DialogActions>
                    </TableCell>
                  )
                )}
              </React.Fragment>
            )
          }
        </PermissibleRender>
      ))}
    </React.Fragment>
  );
};
