import { yupResolver } from "@hookform/resolvers/yup";
import MonacoEditor from "@monaco-editor/react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Autocomplete, Box, Button, Checkbox, Divider, FormControl, Grid, MenuItem, Tab, Tabs, TextField } from "@mui/material";
import _, { isArray } from "lodash";
import React, { useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useBXContext } from "src/BXEngine/BXContext";
import { ApiInput } from "src/components/ApiInput";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { BXIconPicker } from "src/components/BXUI/FormControls/IconPicker";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { BXApp } from "src/types/BXAppType";
import axiosServices from "src/utils/axios";
import { decompressData } from "src/utils/services";
import * as yup from "yup";
import SelectViewComponent from "../../../AppBuilder/components/SelectViewComponent";
import StatusMessages from "../../../AppBuilder/forms/StatusMessages";
import OASSelector from "../../../OASSelector";
import { ColorPicker } from "../ColorPicker";

export const actionTypes = [
  { id: -1, type: "API" },
  { id: -2, type: "Download" },
  { id: -4, type: "Copy" },
  { id: -5, type: "Link" },
  { id: -6, type: "Views" },
  { id: -7, type: "None" },
  { id: -8, type: "Dismiss" },
  { id: -9, type: "BuildX Login" },
  { id: -10, type: "Add item" },
  { id: -11, type: "Delete item" },
  { id: -12, type: "Update item" },
  { id: -13, type: "Refresh Views" },
  { id: -14, type: "Reset Form" },
  { id: -15, type: "Stepper Next" },
  { id: -16, type: "Stepper Previous" },
  { id: -17, type: "Select Stepper" },
  { id: -18, type: "Timer" },
  { id: -19, type: "Show Snackbar" },
  { id: -20, type: "Clean Dirty" },
  { id: -21, type: "Trigger" },
  { id: -22, type: "Visibility Toggle" },
  { id: -23, type: "Start Loading" },
  { id: -24, type: "End Loading" },
  { id: -25, type: "Trigger Data Source" },
  { id: -26, type: "Confirm" },
  { id: -27, type: "Cancel" },
  { id: -28, type: "Run Validation" },
  { id: -29, type: "Check Dirty" },
  { id: -30, type: "Next Page" },
  { id: -31, type: "Previous Page" },
  { id: -32, type: "Clear Validation" },
  { id: -33, type: "Stripe Payment" },
  { id: -34, type: "BuildX Logout" },
];

//Grouped Actions and Labels for UI Display
const groupedActions = [
  {
    group: "",
    actions: [{ id: -7, label: "None" }],
  },
  {
    group: "Miscellaneous",
    actions: [
      { id: -1, label: "API" },
      { id: -5, label: "Navigate" },
      { id: -21, label: "Trigger Action" },
      { id: -2, label: "Download" },
      { id: -4, label: "Copy" },
      { id: -18, label: "Timer" },
      { id: -33, label: "Stripe Payment" },
    ],
  },
  {
    group: "View/Display Management",
    actions: [
      { id: -6, label: "Open View in Modal" },
      { id: -13, label: "Refresh Views" },
      { id: -22, label: "Visibility Toggle" },
      { id: -8, label: "Dismiss" },
      { id: -26, label: "Confirm" },
      { id: -27, label: "Cancel" },
    ],
  },
  {
    group: "State & Props Management",
    actions: [
      { id: -12, label: "Set (State/Prop)" },
      { id: -10, label: "Add (State/Prop)" },
      { id: -11, label: "Delete (State/Prop)" },
    ],
  },
  {
    group: "Form Actions",
    actions: [
      { id: -14, label: "Reset Form" },
      { id: -28, label: "Run Validation" },
      { id: -32, label: "Clear Validation" }, // Implementation pending
      { id: -29, label: "Check Dirty Flag" },
      { id: -20, label: "Clear Dirty Flag" },
    ],
  },
  {
    group: "User Interaction / Feedback",
    actions: [
      { id: -19, label: "Show Snackbar" },
      { id: -23, label: "Start Loading" },
      { id: -24, label: "End Loading" },
    ],
  },
  {
    group: "Data Source Actions",
    actions: [
      { id: -25, label: "Trigger Data Source" },
      { id: -30, label: "Next Page" },
      { id: -31, label: "Prev Page" },
    ],
  },
  {
    group: "Stepper Navigation Actions",
    actions: [
      { id: -17, label: "Stepper Select" },
      { id: -15, label: "Stepper Next" },
      { id: -16, label: "Stepper Previous" },
    ],
  },
  {
    group: "Other",
    actions: [
      { id: -9, label: "BuildX Login" },
      { id: -34, label: "BuildX Logout" },
    ],
  },
];

//Input array for the Autocomplete component
const flattenedActions = groupedActions.flatMap(group =>
  group.actions.map(action => ({
    group: group.group,
    action: action,
  }))
);

const requiredMessage = "Field is required";
export const ActionConfigContent = ({
  item,
  view,
  onActionConfigSave,
  views,
  index,
  getAction,
  appId,
  collectionId,
  pageId,
  selectedChainOfActions,
}) => {
  const prefix = getAction(`actions`)?.[index]?.actionConfig;
  const data = views?.filter(item => item?.id != view?.id);
  const filteredActions = item?.config?.actionMappingConfig?.isLoadingForEntireChain?.[selectedChainOfActions]
    ? actionTypes.filter(ac => !ac.type.includes("Loading"))
    : actionTypes;
  const actionSourceTypes = [...filteredActions];
  data?.forEach((item: any) => actionSourceTypes.push({ ...item, _invisible: true }));
  const [actionTabValue, setActionTabValue] = useState("api");
  const [isRefreshing, setIsRefreshing] = useState(false);
  const prefixActionPath = `actionConfig`;
  const actionViews = [] as any;
  data?.forEach((item: any) => actionViews.push(item));
  const { setAppDescriptor, setSelectedAppId, currentApp } = useBXContext();
  const snackbarDefaultValue = currentApp?.appConfig?.snackbar;
  const schema = yup.object().shape({
    actionConfig: yup
      .object({
        actionSourceViewType: yup.object().when("actionSourceType.type", {
          is: "Views",
          then: yup.object().shape({
            id: yup.string().required(requiredMessage),
            type: yup.string().required(requiredMessage),
          }),
          otherwise: yup.object().nullable(),
        }),
        copySource: yup.string().when("actionSourceType.type", {
          is: "Copy",
          then: yup.string().required(requiredMessage),
          otherwise: yup.string(),
        }),
        keyReference: yup.string().when("actionSourceType.type", {
          is: "Visibility Toggle",
          then: yup.string().required(requiredMessage),
          otherwise: yup.string(),
        }),
        stepperGroupReference: yup.string().when("actionSourceType.type", {
          is: value => ["Stepper Next", "Stepper Previous", "Select Stepper"].includes(value),
          then: yup.string().required(requiredMessage),
          otherwise: yup.string(),
        }),
        dataReference: yup.string().when("actionSourceType.type", {
          is: value => ["Add item", "Delete item", "Update item", "Run Validation", "Clean Dirty", "Check Dirty"].includes(value),
          then: yup.string().required(requiredMessage),
          otherwise: yup.string(),
        }),
        dataSourceKey: yup.string().when("actionSourceType.type", {
          is: value => ["Next Page", "Previous Page", "Trigger Data Source"].includes(value),
          then: yup.string().required(requiredMessage),
          otherwise: yup.string(),
        }),
        paginationBarKey: yup.string().when("actionSourceType.type", {
          is: value => ["Next Page", "Previous Page"].includes(value),
          then: yup.string().required(requiredMessage),
          otherwise: yup.string(),
        }),
        timerValue: yup.string().when("actionSourceType.type", {
          is: "Timer",
          then: yup.string().required(requiredMessage),
          otherwise: yup.string(),
        }),
        updatedValue: yup.mixed().when(["actionSourceType.type", "selectedType"], {
          is: (actionSourceType, selectedType) => actionSourceType === "Update item" && selectedType === "string",
          then: yup.mixed().required(requiredMessage),
          otherwise: yup.mixed().notRequired(),
        }),
        showSnackbar: yup.boolean().when("actionSourceType.type", {
          is: actionSourceType => actionSourceType !== "None" && actionSourceType !== "Views",
          then: yup.boolean().default(true),
        }),
        downloadType: yup.string().when("actionSourceType.type", {
          is: "Download",
          then: yup.string().required(requiredMessage),
          otherwise: yup.string(),
        }),
        triggerSelf: yup.boolean().when("actionSourceType.type", {
          is: "Trigger",
          then: yup.boolean(),
        }),
        triggerAwait: yup.boolean().when("actionSourceType.type", {
          is: "Trigger",
          then: yup.boolean(),
        }),
        triggerKey: yup.string().when(["actionSourceType.type", "triggerSelf"], {
          is: (type, triggerSelf) => type === "Trigger" && !triggerSelf,
          then: yup
            .string()
            .required(requiredMessage)
            .test("", "Can't be trigger self with wait", function (value) {
              const { triggerAwait } = this.parent;
              const key = item?.props?.key;

              if (triggerAwait && value === key) {
                return false;
              }

              return true;
            }),
          otherwise: yup.string(),
        }),
        BXLogin: yup.object().when("actionSourceType.type", {
          is: "BuildX Login",
          then: yup.object().shape({
            emailField: yup.string().required(requiredMessage),
            passwordField: yup.string().required(requiredMessage),
          }),
        }),
        snackbarMessage: yup.string().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.string().required(requiredMessage),
        }),
        snackbarVariant: yup.string().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.string().default(snackbarDefaultValue?.variant),
        }),
        snackbarPosition: yup.string().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.string(),
        }),
        snackbarDuration: yup.string().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.string(),
        }),
        snackbarActionButton: yup.string().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.string(),
        }),
        snackbarPreventDuplicate: yup.boolean().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.boolean().default(false),
        }),
        isAdvance: yup.boolean().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.boolean().default(false),
        }),
        backgroundColorAdvance: yup.string().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.string(),
        }),
        colorAdvance: yup.string().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.string(),
        }),
        iconAdvance: yup.string().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.string(),
        }),
        iconColor: yup.string().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.string(),
        }),
        alignText: yup.string().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.string().default("left"),
        }),

        isLeft: yup.boolean().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.boolean().default(false),
        }),
        variantsSettings: yup.object().when("actionSourceType.type", {
          is: "Show Snackbar",
          then: yup.object(),
        }),
        otherwise: yup.object().shape({
          emailField: yup.string(),
          passwordField: yup.string(),
        }),
        source: yup.string().test("source-validation", requiredMessage, function (value) {
          const { actionSourceType, downloadType } = this.parent;
          if (
            (actionSourceType.type === "API" && actionTabValue === "api") ||
            (actionSourceType.type === "Download" && downloadType === "directUrl")
          ) {
            return value ? true : false;
          }
          return true;
        }),
        method: yup.string().when("actionSourceType.type", {
          is: "API",
          then: yup.string().required("Field is required").default("GET"),
        }),
        isDismissibleView: yup.string().when("actionSourceType.type", {
          is: "API",
          then: yup.string().required("Field is required"),
        }),
        directUrl: yup.string().when(["actionSourceType.type", "downloadType"], {
          is: (type, downloadType) => type === "Download" && downloadType === "directUrl",
          then: yup.string().required(requiredMessage),
          otherwise: yup.string(),
        }),
      })
      .required(requiredMessage),
  });

  const { handleSubmit, formState, control, setValue, getValues, watch, reset } = useForm<FieldValues>({
    defaultValues: {
      actionConfig: {
        ...prefix,
        actionSourceType: prefix?.actionSourceType?.id ? prefix?.actionSourceType : { id: -7, type: "None" },
        actionCondition: prefix?.actionCondition ?? prefix?.condition,
        showModal: prefix?.showModal || "No",
        modalSize: prefix?.modalSize || "lg",
        actionViewType: prefix?.actionViewType || "Modal",
        isDismissibleView: prefix?.isDismissibleView || "Yes",
        downloadType: prefix?.downloadType || "directUrl",
        linkUrl: prefix?.linkUrl || "",
        openLinkAs: prefix?.openLinkAs || "_blank",
        showHeader: prefix?.showHeader ?? true,
        isResetEnabled: prefix?.isResetEnabled ?? true,
        selectedType: prefix?.selectedType || "string",
        abort: prefix?.abort ?? true,
        variantsSettings: {
          ...snackbarDefaultValue?.variantsSettings,
        },
        snackbarPosition: prefix?.snackbarPosition || "Inherent from app",
        snackbarActionButton: prefix?.snackbarActionButton || "Inherent from app",
        snackbarVariant: snackbarDefaultValue?.allVariants?.includes(prefix?.snackbarVariant)
          ? prefix?.snackbarVariant
          : snackbarDefaultValue?.variant,
      },
    },
    reValidateMode: "onChange",
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const errors = formState?.errors as any;
  const isDirty = formState?.isDirty;
  useCallbackPrompt(isDirty);

  const prefixActionErrorPath = _.get(errors, `actionConfig`);

  const isView =
    watch(`${prefixActionPath}.actionSourceType`)?.type != "API" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Download" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Link" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Copy" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Visibility Toggle" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "None" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Refresh Views" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Reset Form" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Start Loading" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "End Loading" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Show Snackbar" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "BuildX Login" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Add item" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Delete item" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Clean Dirty" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Update item" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Trigger" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Stepper Next" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Stepper Previous" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Select Stepper" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Timer" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Confirm" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Cancel" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Trigger Data Source" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Next Page" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Previous Page" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Run Validation" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Check Dirty" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Dismiss" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Clear Validation" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "Stripe Payment" &&
    watch(`${prefixActionPath}.actionSourceType`)?.type != "BuildX Logout" &&
    watch(`${prefixActionPath}.actionSourceType`) !== null;

  const handleActionClick = values => {
    const _values = values;
    if (isView) {
      _values.actionConfig.actionSourceType = watch("actionConfig.actionSourceViewType");
    }
    onActionConfigSave(_values);
  };

  const currentActionId = isView ? -6 : watch(`${prefixActionPath}.actionSourceType`)?.id;
  const actionSourceTypeCurrentValue = flattenedActions.find(item => item.action.id === currentActionId);

  const openNewWindow = () => {
    const urlToNavigate = `/buildx/app?appId=${appId}&collectionId=${collectionId}&pageId=${pageId}`;
    window.open(urlToNavigate, "_blank");
  };

  const openView = viewBuilderId => {
    const url = `${viewBuilderId}`;
    window.open(url, "_blank");
  };

  const handleAppSelect = (item: any) => {
    return new Promise<void>((resolve, reject) => {
      setSelectedAppId(item);
      if (item?.templateConfig == null) {
        axiosServices.get("/application/" + item).then(({ data }) => {
          if (data?.appConfig) {
            data.appConfig = decompressData(data?.appConfig);
          }
          if (data?.templateConfig) {
            data.templateConfig = decompressData(data?.templateConfig);
          }
          setAppDescriptor((prev: BXApp[]) => prev.map(app => (app.id == data?.id ? data : app)));
          resolve();
        });
      }
    });
  };

  const refreshViewsList = watch(`${prefixActionPath}.refreshActionView`);
  // * Check the views list type for the refresh to backward compatibility with prev. version
  const _type = typeof refreshViewsList?.[0];

  const autocompleteDefaultValue = (() => {
    if (_type === "string") {
      return refreshViewsList?.map(name => {
        const { id } = views?.find(view => view?.info?.name === name) || {};
        return {
          id: id,
          name,
        };
      });
    }

    if (!refreshViewsList || refreshViewsList?.length === 0) {
      return [];
    }

    return (
      (isArray(refreshViewsList)
        ? refreshViewsList.map(view => {
            const _view = views?.find(v => v?.id === view.id);
            return {
              ...view,
              name: _view?.info?.name,
            };
          })
        : [
            {
              ...refreshViewsList,
              name: views?.find(view => view?.id === view.id)?.info?.name,
            },
          ]) || []
    );
  })();
  const defaultVariants = ["default", "success", "error", "info", "warning"];
  const snackbarVariant = watch(`${prefixActionPath}.snackbarVariant`);

  return (
    <React.Fragment>
      <Grid container xs={12} sx={{ marginBottom: 2 }}>
        <Grid item xs={12}>
          <BXInput
            label='Action Condition'
            fullWidth
            name={`${prefixActionPath}.actionCondition`}
            control={control}
            error={prefixActionErrorPath?.actionCondition}
          />
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: 2 }}>
          <BXSwitch sx={{ ml: 2 }} label='Abort On Failure' name={`${prefixActionPath}.abort`} control={control} />
        </Grid>
        {watch(`${prefixActionPath}.actionSourceType`)?.type !== "None" &&
          watch(`${prefixActionPath}.actionSourceType`)?.type !== "Views" &&
          watch(`${prefixActionPath}.actionSourceType`)?.type !== "Show Snackbar" && (
            <Grid item xs={12} sx={{ paddingTop: 2 }}>
              <BXSwitch
                sx={{ ml: 2 }}
                label='Show Default Snackbar'
                control={control}
                error={prefixActionErrorPath?.showSnackbar}
                defaultChecked={prefixActionErrorPath?.showSnackbar ?? true}
                name={`${prefixActionPath}.showSnackbar`}
              />
            </Grid>
          )}
        <Grid item xs={6} sx={{ paddingTop: 2 }}>
          <FormControl fullWidth>
            {/* <BXInput
              name={`actionConfig.actionSourceType.id`}
              control={control}
              error={prefixActionErrorPath?.actionSourceType?.id}
              select
              value={actionSourceTypeCurrentValue}
              label={"Source"}
              id='demo-simple-select'
              onChange={event => {
                const selectedId = event?.target.value;
                const view = actionSourceTypes.find((view: any) => view?.id === selectedId);
                setValue(`actionConfig.actionSourceType`, { type: view?.type, id: view?.id });
              }}
            >
              {actionSourceTypes.map((item: any) => (
                <MenuItem key={item?.id} value={item?.id} style={{ display: item?._invisible ? "none" : "block" }}>
                  {item?.info?.name || item?.type}
                </MenuItem>
              ))}
            </BXInput> */}
            <Autocomplete
              value={actionSourceTypeCurrentValue}
              options={flattenedActions}
              groupBy={option => option.group}
              renderGroup={params => (
                <React.Fragment key={params.key}>
                  <Box
                    sx={{
                      backgroundColor: !_.isEmpty(params.group) ? "rgb(24, 119, 242)" : undefined,
                      fontWeight: !_.isEmpty(params.group) ? "bold" : undefined,
                      cursor: !_.isEmpty(params.group) ? "default" : undefined,
                      color: !_.isEmpty(params.group) ? "#ffffff" : undefined,
                      padding: !_.isEmpty(params.group) ? "7px" : undefined,
                    }}
                  >
                    {params.group}
                  </Box>
                  {params.children}
                </React.Fragment>
              )}
              getOptionLabel={option => option.action.label}
              renderInput={params => <TextField {...params} label='Select Action' />}
              onChange={(_, val) => {
                if (!val) {
                  //Empty user input
                  setValue(`actionConfig.actionSourceType`, null);
                } else {
                  const selectedActionId = val?.action?.id;
                  const selectedAction = actionSourceTypes.find(ac => ac.id === selectedActionId);
                  setValue(`actionConfig.actionSourceType`, selectedAction);
                }
              }}
              componentsProps={{
                clearIndicator: {
                  sx: {
                    marginRight: "7px",
                  },
                },
              }}
              noOptionsText='No actions available'
            />
          </FormControl>
        </Grid>
      </Grid>

      {isView && (
        <SelectViewComponent
          fieldName={`actionConfig.actionSourceViewType.id`}
          fieldObject={`actionConfig.actionSourceViewType`}
          isRefreshing={isRefreshing}
          setIsRefreshing={setIsRefreshing}
          handleAppSelect={handleAppSelect}
          appId={appId}
          openView={openView}
          openNewWindow={openNewWindow}
          watch={watch}
          control={control}
          error={prefixActionErrorPath?.actionSourceViewType?.id}
          formBuilderViews={actionViews}
          setValue={setValue}
        />
      )}

      <Divider sx={{ paddingTop: 2 }} />
      <TabContext value={actionTabValue}>
        {watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
          <TabList
            onChange={(event, newValue) => {
              setActionTabValue(newValue);
            }}
          >
            <Tab sx={{ textTransform: "none" }} label='API' value='api' />
            <Tab sx={{ textTransform: "none" }} label='Messages' value='messages' />
          </TabList>
        )}
        <TabPanel sx={{ width: "100%" }} value='api'>
          <Grid container spacing={2.5}>
            {watch(`${prefixActionPath}.actionSourceType`)?.type == "Download" && (
              <Tabs
                value={watch(`${prefixActionPath}.downloadType`)}
                onChange={(_, newValue) => {
                  setValue(`${prefixActionPath}.downloadType`, newValue);
                }}
                aria-label='basic tabs example'
              >
                <Tab sx={{ textTransform: "none" }} label='Direct Url' value='directUrl' />
                <Tab sx={{ textTransform: "none" }} label='API' value='api' />
              </Tabs>
            )}
            {(watch(`${prefixActionPath}.actionSourceType`)?.type == "API" ||
              (watch(`${prefixActionPath}.actionSourceType`)?.type == "Download" &&
                watch(`${prefixActionPath}.downloadType`) === "api")) && (
              <Grid item xs={12}>
                <ApiInput
                  watch={watch}
                  getValues={getValues}
                  setValue={setValue}
                  error={prefixActionErrorPath}
                  errorURL={prefixActionErrorPath?.source}
                  apiLabel='Url'
                  control={control}
                  path={`${prefixActionPath}`}
                  pathURL={`${prefixActionPath}.source`}
                  OASElement={
                    <OASSelector
                      swaggerProps={{
                        type: "action",
                        isForm: view?.type === "form",
                        template: watch("dataSource.template"),
                        columns: watch("config.columns"),
                        onSuccess: (values: any) => {
                          setValue(`${prefixActionPath}.uri`, values.action.source);
                          setValue(`${prefixActionPath}.source`, values.action.source);
                          setValue(`${prefixActionPath}.method`, (values.action.method as string).toUpperCase());
                          setValue(`${prefixActionPath}.body`, formatJSON(values.action.body));
                          setValue(`${prefixActionPath}.headers`, values.action.headers);
                        },
                      }}
                    />
                  }
                />
              </Grid>
            )}
            {watch(`${prefixActionPath}.downloadType`) === "api" && watch(`${prefixActionPath}.actionSourceType`)?.type == "Download" && (
              <Grid item xs={12}>
                <BXInput
                  label={"Key"}
                  name={`${prefixActionPath}.downloadKey`}
                  control={control}
                  error={prefixActionErrorPath?.downloadKey}
                />
              </Grid>
            )}
            {watch(`${prefixActionPath}.downloadType`) === "directUrl" &&
              watch(`${prefixActionPath}.actionSourceType`)?.type == "Download" && (
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <BXInput
                      label={"Url"}
                      name={`${prefixActionPath}.directUrl`}
                      control={control}
                      error={prefixActionErrorPath?.directUrl}
                    />
                  </Grid>
                </Grid>
              )}
            {watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
              <>
                {view?.type === "form-builder" && (
                  <Grid item xs={12}>
                    <BXInput
                      label={"Response Root Key"}
                      variant='outlined'
                      control={control}
                      name={`${prefixActionPath}.dataEntry`}
                      error={prefixActionErrorPath?.dataEntry}
                    />
                  </Grid>
                )}
              </>
            )}
            {watch(`${prefixActionPath}.actionSourceType`)?.type == "Download" && (
              <Grid item xs={4}>
                <BXSwitch
                  name={`${prefixActionPath}.openUrl`}
                  control={control}
                  error={prefixActionErrorPath?.openUrl}
                  label={"Open Url"}
                />
              </Grid>
            )}
            {watch(`${prefixActionPath}.actionSourceType`)?.type == "Visibility Toggle" && (
              <>
                <Grid item xs={12}>
                  <BXInput
                    label={"Key Reference"}
                    fullWidth
                    name={`${prefixActionPath}.keyReference`}
                    control={control}
                    error={prefixActionErrorPath?.keyReference}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BXSwitch
                    name={`${prefixActionPath}.displayRef`}
                    control={control}
                    error={prefixActionErrorPath?.displayRef}
                    label={watch(`${prefixActionPath}.displayRef`) ? "Visibility Hidden" : "Display None"}
                  />
                </Grid>
              </>
            )}
            {watch(`${prefixActionPath}.actionSourceType`)?.type === "Refresh Views" && (
              <Grid item xs={12}>
                <Autocomplete
                  defaultValue={autocompleteDefaultValue}
                  fullWidth
                  multiple
                  id='views-autocomplete'
                  options={views.map((view: any) => ({ id: view?.id, name: view?.info?.name }))}
                  getOptionLabel={option => option?.name || ""}
                  onChange={(e, value) => {
                    setValue(`${prefixActionPath}.refreshActionView`, value);
                  }}
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props} key={option?.id}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                          checkedIcon={<CheckBoxIcon fontSize='small' />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.name}
                      </li>
                    );
                  }}
                  renderInput={params => <TextField {...params} label='Views' placeholder='' />}
                />
              </Grid>
            )}

            {watch(`${prefixActionPath}.actionSourceType`)?.type === "Copy" && (
              <Grid item xs={12}>
                <BXInput
                  label={"Copy Text Source"}
                  fullWidth
                  name={`${prefixActionPath}.copySource`}
                  control={control}
                  error={prefixActionErrorPath?.copySource}
                />
              </Grid>
            )}
            {(watch(`${prefixActionPath}.actionSourceType`)?.type === "Stepper Next" ||
              watch(`${prefixActionPath}.actionSourceType`)?.type === "Select Stepper" ||
              watch(`${prefixActionPath}.actionSourceType`)?.type === "Stepper Previous") && (
              <>
                <Grid item xs={12}>
                  <BXInput
                    label={"Stepper Group Reference"}
                    fullWidth
                    name={`${prefixActionPath}.stepperGroupReference`}
                    control={control}
                    error={prefixActionErrorPath?.stepperGroupReference}
                  />
                </Grid>
              </>
            )}
            {watch(`${prefixActionPath}.actionSourceType`)?.type === "Select Stepper" && (
              <Grid item xs={12}>
                <BXInput
                  label={"page index or reference"}
                  fullWidth
                  name={`${prefixActionPath}.stepperIndex`}
                  control={control}
                  error={prefixActionErrorPath?.stepperIndex}
                />
              </Grid>
            )}
            {watch(`${prefixActionPath}.actionSourceType`)?.type === "Link" && (
              <>
                <Grid item xs={12}>
                  <BXInput
                    label={"URL Link"}
                    fullWidth
                    name={`${prefixActionPath}.linkUrl`}
                    control={control}
                    error={prefixActionErrorPath?.linkUrl}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BXInput
                    label={"Open Link As"}
                    name={`${prefixActionPath}.openLinkAs`}
                    control={control}
                    select
                    error={prefixActionErrorPath?.openLinkAs}
                  >
                    {[
                      { value: "_blank", label: "New Tab" },
                      { value: "_self", label: "Same Tab" },
                    ].map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </BXInput>
                </Grid>
              </>
            )}
            {(view?.type == "card-list" || view?.type == "image-grid" || view?.type == "form-builder") &&
              watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
                <Grid item xs={12}>
                  <BXInput
                    label={"Highlight Value"}
                    fullWidth
                    name={`${prefixActionPath}.highlightedValue`}
                    control={control}
                    error={prefixActionErrorPath?.highlightedValue}
                  />
                </Grid>
              )}
            {view?.type != "form" && watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
              <Grid item xs={6}>
                <BXInput
                  label={"Show Modal"}
                  name={`${prefixActionPath}.showModal`}
                  control={control}
                  select
                  error={prefixActionErrorPath?.showModal}
                >
                  {["Yes", "No"]?.map(item => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </BXInput>
              </Grid>
            )}

            {isView && (
              <>
                <Grid item xs={6}>
                  <BXInput
                    label={"Modal Size"}
                    name={`${prefixActionPath}.modalSize`}
                    control={control}
                    select
                    error={prefixActionErrorPath?.modalSize}
                  >
                    {[
                      { label: "Extra Small (xs)", value: "xs" },
                      { label: "Small (sm)", value: "sm" },
                      { label: "Medium (md)", value: "md" },
                      { label: "Large (lg)", value: "lg" },
                      { label: "Extra Large (xl)", value: "xl" },
                    ]?.map(item => (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </BXInput>
                </Grid>

                <Grid item xs={12}>
                  <BXSwitch
                    name={`${prefixActionPath}.showHeader`}
                    control={control}
                    label={"Show Modal Header"}
                    error={prefixActionErrorPath?.showHeader}
                  />
                </Grid>
              </>
            )}

            {watch(`${prefixActionPath}.actionSourceType`)?.type === "BuildX Login" && (
              <Grid item xs={6}>
                <BXInput
                  label={"Email Field"}
                  fullWidth
                  name={`${prefixActionPath}.BXLogin.emailField`}
                  control={control}
                  error={prefixActionErrorPath?.BXLogin?.emailField}
                />
              </Grid>
            )}

            {watch(`${prefixActionPath}.actionSourceType`)?.type === "BuildX Login" && (
              <Grid item xs={6}>
                <BXInput
                  label={"Password Field"}
                  fullWidth
                  name={`${prefixActionPath}.BXLogin.passwordField`}
                  control={control}
                  error={prefixActionErrorPath?.BXLogin?.passwordField}
                />
              </Grid>
            )}

            {watch(`${prefixActionPath}.actionSourceType`)?.type == "Show Snackbar" && (
              <>
                <Grid item xs={6}>
                  <BXInput
                    label={"Message"}
                    fullWidth
                    name={`${prefixActionPath}.snackbarMessage`}
                    control={control}
                    error={prefixActionErrorPath?.snackbarMessage}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BXInput
                    label={"Duration"}
                    fullWidth
                    name={`${prefixActionPath}.snackbarDuration`}
                    control={control}
                    error={prefixActionErrorPath?.snackbarDuration}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BXInput
                    label={"Variant"}
                    name={`${prefixActionPath}.snackbarVariant`}
                    control={control}
                    defaultValue={watch(`${prefixActionPath}.snackbarVariant`) || snackbarDefaultValue?.variant}
                    select
                    error={prefixActionErrorPath?.snackbarVariant}
                  >
                    {snackbarDefaultValue?.allVariants?.map(item => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </BXInput>
                </Grid>
                <Grid item xs={6}>
                  <BXInput
                    label={"Position"}
                    name={`${prefixActionPath}.snackbarPosition`}
                    control={control}
                    defaultValue={"Inherent from app"}
                    select
                    error={prefixActionErrorPath?.snackbarPosition}
                  >
                    {["Inherent from app", "top-left", "top-center", "top-right", "bottom-left", "bottom-center", "bottom-right"]?.map(
                      item => (
                        <MenuItem key={item} value={item} style={item === "" ? { height: "30px" } : {}}>
                          {item}
                        </MenuItem>
                      )
                    )}
                  </BXInput>
                </Grid>
                <Grid item xs={6}>
                  <BXInput
                    label={"Action Button"}
                    name={`${prefixActionPath}.snackbarActionButton`}
                    control={control}
                    select
                    error={prefixActionErrorPath?.snackbarActionButton}
                  >
                    {["Inherent from app", "Without Button", "Dismiss", "Icon X", "Corner X"]?.map(item => (
                      <MenuItem key={item} value={item} style={item === "" ? { height: "30px" } : {}}>
                        {item}
                      </MenuItem>
                    ))}
                  </BXInput>
                </Grid>
                <Grid item xs={6}>
                  <BXSwitch
                    name={`${prefixActionPath}.snackbarPreventDuplicate`}
                    control={control}
                    defaultChecked={false}
                    label={"Prevent Duplicate"}
                    error={prefixActionErrorPath?.snackbarPreventDuplicate}
                  />
                </Grid>
                {!defaultVariants.includes(watch(`${prefixActionPath}.snackbarVariant`)) && (
                  <>
                    <Grid item xs={6}>
                      <ColorPicker
                        disabled
                        key={snackbarVariant}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        name={`${prefixActionPath}.variantsSettings.${snackbarVariant}.backgroundColorAdvance`}
                        label='Background Color'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ColorPicker
                        disabled
                        key={snackbarVariant}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        name={`${prefixActionPath}.variantsSettings.${snackbarVariant}.colorAdvance`}
                        label='Font Color'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <BXIconPicker
                        disabled
                        key={snackbarVariant}
                        control={control}
                        name={`${prefixActionPath}.variantsSettings.${snackbarVariant}.iconAdvance.icon`}
                        label='Icon'
                        defaultValue={watch(`${prefixActionPath}.variantsSettings.${snackbarVariant}.iconAdvance.icon`) || ""}
                        previewIcon={true}
                        onChange={value => setValue(`${prefixActionPath}.variantsSettings.${snackbarVariant}.iconAdvance.icon`, value)}
                        showCaption={false}
                        inputLabelProps={{ style: { fontSize: "12px" } }}
                        inputProps={{ style: { fontSize: "12px" } }}
                        size='medium'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ColorPicker
                        disabled
                        key={snackbarVariant}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        name={`${prefixActionPath}.variantsSettings.${snackbarVariant}.iconColor`}
                        label='Icon Color'
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <BXInput
                        disabled
                        key={snackbarVariant}
                        label={"Align Text"}
                        name={`${prefixActionPath}.variantsSettings.${snackbarVariant}.alignText`}
                        control={control}
                        defaultValue={"left"}
                        select
                        error={prefixActionErrorPath?.alignText}
                      >
                        {["left", "center", "right"]?.map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </BXInput>
                    </Grid>
                  </>
                )}
              </>
            )}

            {watch(`${prefixActionPath}.actionSourceType`)?.type == "API" && (
              <>
                <Grid item xs={6}>
                  <BXInput
                    label={"Dismissible View"}
                    name={`${prefixActionPath}.isDismissibleView`}
                    control={control}
                    select
                    error={prefixActionErrorPath?.isDismissibleView}
                  >
                    {["Yes", "No"]?.map(item => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </BXInput>
                </Grid>
              </>
            )}
            {watch(`${prefixActionPath}.actionSourceType`)?.type == "Timer" && (
              <Grid item xs={6}>
                <BXInput
                  label={"Timer"}
                  fullWidth
                  name={`${prefixActionPath}.timerValue`}
                  control={control}
                  error={prefixActionErrorPath?.timerValue}
                />
              </Grid>
            )}
            {(watch(`${prefixActionPath}.actionSourceType`)?.type == "Add item" ||
              watch(`${prefixActionPath}.actionSourceType`)?.type == "Update item" ||
              watch(`${prefixActionPath}.actionSourceType`)?.type == "Clean Dirty" ||
              watch(`${prefixActionPath}.actionSourceType`)?.type == "Clear Validation" ||
              watch(`${prefixActionPath}.actionSourceType`)?.type == "Run Validation" ||
              watch(`${prefixActionPath}.actionSourceType`)?.type == "Check Dirty" ||
              watch(`${prefixActionPath}.actionSourceType`)?.type == "Delete item") && (
              <>
                {watch(`${prefixActionPath}.actionSourceType`)?.type === "Run Validation" && (
                  <Grid item xs={12}>
                    <BXSwitch
                      name={`${prefixActionPath}.scrollToError`}
                      control={control}
                      defaultChecked={true}
                      label={"Scroll to Error"}
                      error={prefixActionErrorPath?.scrollToError}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <BXInput
                    label={"Key item"}
                    fullWidth
                    name={`${prefixActionPath}.dataReference`}
                    control={control}
                    error={prefixActionErrorPath?.dataReference}
                  />
                </Grid>
                {watch(`${prefixActionPath}.actionSourceType`)?.type === "Update item" && (
                  <Grid item xs={12} pt={2} spacing={2}>
                    <Grid item xs={3}>
                      <BXInput
                        variant='outlined'
                        select
                        label='Type'
                        control={control}
                        name={`${prefixActionPath}.selectedType`}
                        error={prefixActionErrorPath?.selectedType}
                        onChange={e => {
                          const selectedType = e.target.value;
                          setValue(`${prefixActionPath}.selectedType`, selectedType);

                          if (selectedType === "undefined") {
                            setValue(`${prefixActionPath}.updatedValue`, undefined);
                          } else if (selectedType === "null") {
                            setValue(`${prefixActionPath}.updatedValue`, null);
                          } else if (selectedType === "boolean") {
                            const currentValue = getValues(`${prefixActionPath}.updatedValue`);
                            if (currentValue !== true && currentValue !== false) {
                              setValue(`${prefixActionPath}.updatedValue`, false);
                            }
                          } else if (selectedType === "string") {
                            const currentValue = getValues(`${prefixActionPath}.updatedValue`);
                            if (currentValue === true || currentValue === false) {
                              setValue(`${prefixActionPath}.updatedValue`, undefined);
                            }
                          }
                        }}
                      >
                        {["string", "boolean", "undefined", "null", "object"].map(item => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </BXInput>
                    </Grid>

                    {watch(`${prefixActionPath}.selectedType`) === "string" && (
                      <Grid item xs={9} pt={2}>
                        <BXInput
                          label='Updated Value'
                          fullWidth
                          name={`${prefixActionPath}.updatedValue`}
                          control={control}
                          error={prefixActionErrorPath?.updatedValue}
                        />
                      </Grid>
                    )}
                    {watch(`${prefixActionPath}.selectedType`) === "boolean" && (
                      <Grid item xs={9} pt={2}>
                        <BXSwitch
                          sx={{ ml: 2 }}
                          label='Updated Value'
                          control={control}
                          error={prefixActionErrorPath?.updatedValue}
                          name={`${prefixActionPath}.updatedValue`}
                        />
                      </Grid>
                    )}

                    {watch(`${prefixActionPath}.selectedType`) === "object" && (
                      <Grid item xs={12} pt={2}>
                        <Controller
                          name={`${prefixActionPath}.updatedValue`}
                          control={control}
                          render={({ field }) => (
                            <MonacoEditor
                              width='100%'
                              height='200px'
                              language='json'
                              theme='vs-dark'
                              value={field.value || "{}"}
                              options={{ colorDecorators: true }}
                              onChange={newValue => field.onChange(newValue)}
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </>
            )}
            {["Next Page", "Previous Page", "Trigger Data Source"].includes(watch(`${prefixActionPath}.actionSourceType`)?.type) && (
              <Grid item xs={12}>
                <BXInput
                  label={"Data Source Key"}
                  fullWidth
                  name={`${prefixActionPath}.dataSourceKey`}
                  control={control}
                  error={prefixActionErrorPath?.dataSourceKey}
                />
              </Grid>
            )}
            {["Next Page", "Previous Page"].includes(watch(`${prefixActionPath}.actionSourceType`)?.type) && (
              <Grid item xs={12}>
                <BXInput
                  label={"Pagination Bar"}
                  fullWidth
                  name={`${prefixActionPath}.paginationBarKey`}
                  control={control}
                  error={prefixActionErrorPath?.paginationBarKey}
                />
              </Grid>
            )}
            {watch(`${prefixActionPath}.actionSourceType`)?.type == "Trigger" && (
              <FormControl>
                <Grid container display={"flex"} justifyContent={"start"} alignItems={"center"} spacing={1}>
                  <Grid item xs={4}>
                    <BXSwitch name={`${prefixActionPath}.triggerSelf`} control={control} sx={{ ml: 2 }} label='Trigger Self' />
                  </Grid>
                  <Grid item xs={2}>
                    <BXSwitch
                      name={`${prefixActionPath}.triggerAwait`}
                      control={control}
                      sx={{ ml: 2 }}
                      label='Wait'
                      disabled={watch(`${prefixActionPath}.triggerSelf`)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <BXInput
                      label={"Actionable Key Reference"}
                      fullWidth
                      sx={{ ml: 2 }}
                      name={`${prefixActionPath}.triggerKey`}
                      control={control}
                      disabled={watch(`${prefixActionPath}.triggerSelf`)}
                      error={prefixActionErrorPath?.triggerKey}
                    />
                  </Grid>
                </Grid>
              </FormControl>
            )}
            {watch(`${prefixActionPath}.enableCSV`) && (
              <>
                {watch(`${prefixActionPath}.actionSourceType`)?.type === "API" && (
                  <Grid item xs={12}>
                    <BXInput
                      label={"Duration (ms)"}
                      fullWidth
                      name={`${prefixActionPath}.duration`}
                      control={control}
                      error={prefixActionErrorPath?.duration}
                    />
                  </Grid>
                )}
                {watch(`${prefixActionPath}.actionSourceType`)?.type === "API" && (
                  <Grid item xs={12}>
                    <BXInput
                      label={"Max Calls"}
                      fullWidth
                      name={`${prefixActionPath}.maxCalls`}
                      control={control}
                      error={prefixActionErrorPath?.calls}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </TabPanel>

        <TabPanel sx={{ width: "100%" }} value='messages'>
          <Grid container spacing={2.5}>
            <StatusMessages
              // actionIndex={index}
              control={control}
              namePrefix={prefixActionPath}
            />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <BXSwitch name={`${prefixActionPath}.dialog.enabled`} control={control} label={"Confirmation"} />
            </Grid>

            <Grid item xs={12}>
              <BXInput
                label={"Title"}
                variant='outlined'
                multiline
                control={control}
                name={`${prefixActionPath}.dialog.title`}
                disabled={!watch(`${prefixActionPath}.dialog.enabled`)}
              />
            </Grid>
            <Grid item xs={12}>
              <BXInput
                label={"Message"}
                variant='outlined'
                multiline
                control={control}
                name={`${prefixActionPath}.dialog.message`}
                disabled={!watch(`${prefixActionPath}.dialog.enabled`)}
              />
            </Grid>
            <Grid item xs={12}>
              <BXInput
                label={"Confirm Button Label"}
                variant='outlined'
                multiline
                control={control}
                name={`${prefixActionPath}.dialog.confirmButtonLabel`}
                disabled={!watch(`${prefixActionPath}.dialog.enabled`)}
              />
            </Grid>
            <Grid item xs={12}>
              <BXInput
                label={"Cancel Button Label"}
                variant='outlined'
                multiline
                control={control}
                name={`${prefixActionPath}.dialog.cancelButtonLabel`}
                disabled={!watch(`${prefixActionPath}.dialog.enabled`)}
              />
            </Grid>
            <Grid>
              <BXSwitch
                name={`${prefixActionPath}.dialog.customViewEnabled`}
                control={control}
                label={"Select Custom View"}
                disabled={!watch(`${prefixActionPath}.dialog.enabled`)}
              />
            </Grid>
            {watch(`${prefixActionPath}.dialog.enabled`) && watch(`${prefixActionPath}.dialog.customViewEnabled`) && (
              //TODO: Change style
              <Grid item xs={12}>
                <BXInput label={"Select View"} variant='outlined' select control={control} name={`${prefixActionPath}.dialog.customView`}>
                  {views?.map(view => (
                    <MenuItem key={view?.id} value={view?.id}>
                      {view?.info?.name}
                    </MenuItem>
                  ))}
                </BXInput>
              </Grid>
            )}
          </Grid>
        </TabPanel>
      </TabContext>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button variant='contained' onClick={handleSubmit(handleActionClick)}>
            Save
          </Button>
        </Box>
      </Grid>
    </React.Fragment>
  );
};
