import { LoadingButton } from "@mui/lab";
import Button, { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import _ from "lodash";
import * as React from "react";
import { FC, useEffect, useMemo } from "react";
import { useBXContext } from "src/BXEngine/BXContext";
import { UIElement } from "src/types/UIElement";
import { getSharedViews } from "src/views/pages/BuildX/FormBuilder/utils";
import { ViewerModal } from "../viewerModal";

type BXConfirmationDialogProps = {
  buttonProps?: ButtonProps;
  onCancel?: () => void;
  onConfirm?: () => void;
  title: string;
  content?: string;
  iconButton?: boolean;
  open?: boolean;
  isLoading?: boolean;
  views?: UIElement[];
  pageId?: string;
  isCustom?: boolean;
  customDialogView?: string;
  customDialogData?: any;
};

export const BXConfirmationDialog: FC<BXConfirmationDialogProps> = ({
  buttonProps,
  onConfirm,
  content,
  isLoading,
  onCancel,
  title,
  open: _open,
  iconButton = false,
  isCustom = false,
  views,
  customDialogView,
  pageId,
  customDialogData,
}) => {
  const [open, setOpen] = React.useState(false);
  const { currentApp } = useBXContext();

  const confirmationView = useMemo(() => {
    const _views = views?.concat(getSharedViews(currentApp));
    return _views?.find(view => view.id === customDialogView);
  }, [views, currentApp, customDialogView]);

  useEffect(() => {
    if (!_.isNil(_open)) {
      setOpen(_open);
    }
  }, [_open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onCancel?.();
  };

  const handleConfirm = async () => {
    await onConfirm?.();
    setOpen(false);
  };

  return isCustom ? (
    <ViewerModal
      viewName={confirmationView?.info?.viewName}
      pageId={pageId}
      selectedView={confirmationView}
      openView={open}
      views={views}
      showHeader={false}
      clearActionView={() => {
        onCancel?.();
      }}
      type={confirmationView?.type}
    />
  ) : (
    <>
      {!_.isNil(_open) ? null : iconButton ? (
        <IconButton {...buttonProps} onClick={handleClickOpen} />
      ) : (
        <Button variant='outlined' {...buttonProps} onClick={handleClickOpen} />
      )}
      <Dialog open={open} onClose={handleClose} fullWidth aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title' style={{ fontSize: "14px" }}>
          {customDialogData?.message || "Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton onClick={handleConfirm} autoFocus variant='contained' loading={isLoading}>
            {customDialogData?.confirmButtonLabel || "Confirm"}
          </LoadingButton>
          <Button onClick={handleClose} variant='outlined'>
            {customDialogData?.cancelButtonLabel || "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
