import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import axios from "axios";
import { debounce } from "lodash";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { BXIcon } from "src/components/BXUI/Icon";
import { CustomAutoCompleteProps } from "./types";
export const CustomAutoCompleteBX: FC<CustomAutoCompleteProps> = props => {
  const {
    label,
    value,
    onChange,
    multiple,
    freeSolo,
    showCheckbox,
    showMore,
    numShowMore,
    dataAutoComplete,
    readOnly,
    keyLabelData,
    keyValueData,
    placeholder,
    addIcon,
    iconRight,
    config,
    suggestionList,
    id,
    metaData,
    arrowIcon,
    filterSelectedOptions,
    addWord,
    listFreeSolo,
  } = props;
  interface OptionType {
    label: string;
    value: string;
  }

  const [options, setOptions] = useState<any[]>([]);

  const additionalData = useRef<any[]>([]);
  const [filteredAdditionalDataApi, setFilteredAdditionalDataApi] = useState<any[]>([]);

  const extraOptions = useRef([]);
  const timeDebounce = Number(config?.suggestionEndpoint?.debounce);
  const debouncedFetchRef = useRef(
    debounce(async inputValue => {
      try {
        const response = await axios.get(config?.suggestionEndpoint?.suggestionEndpoint, {
          params: { Search: inputValue },
        });
        const dataEntry = config?.suggestionEndpoint?.suggestionDataEntry;
        const newFilteredData = response.data?.[dataEntry]?.map(option => ({
          label: option.name || option.todo,
          value: option.name || option.todo,
        }));
        const filteredAdditionalData = newFilteredData?.filter(
          option =>
            option?.label?.toLowerCase().startsWith(inputValue) && !options?.some(existingOption => existingOption?.value === option?.value)
        );
        setFilteredAdditionalDataApi(filteredAdditionalData);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    }, timeDebounce)
  );

  if (config?.suggestionEndpoint?.suggestionSourceType == "SIMPLE" && suggestionList) {
    let dataEntry = config?.suggestionEndpoint?.suggestionDataEntryList;
    additionalData.current = config?.suggestionEndpoint?.suggestedValues[dataEntry];
  }

  useEffect(() => {
    if (Array.isArray(dataAutoComplete) && dataAutoComplete?.length > 0) {
      const options = dataAutoComplete.map(item => ({
        label: item[keyLabelData],
        value: item[keyValueData],
      }));
      setOptions(options);
    }
  }, [dataAutoComplete]);

  const handleOnChangeSingle = (event, newValue) => {
    if (newValue == null) {
      onChange?.("");
    } else if (typeof newValue === "string") {
      onChange?.(newValue);

      if (!options.some(option => option.value === newValue)) {
        const newOption = { label: newValue, value: newValue };
        setOptions(prevOptions => [...prevOptions, newOption]);
      }
    } else {
      const modifiedLabel = newValue.label.startsWith("Add ") ? newValue.label.replace(/^Add\s+/i, "") : newValue.label;

      const newOption = { ...newValue, label: modifiedLabel };

      if (!options.some(option => option.value === newOption.value)) {
        setOptions(prevOptions => [...prevOptions, newOption]);
      }
      onChange?.(newOption.value);
    }
  };

  const handleOnChangeMultiple = (event, newValues) => {
    const newOptions = newValues
      .filter(val => !options.some(option => option.value === (typeof val === "string" ? val : val.value)))
      .map(val => {
        if (typeof val === "string") {
          return { label: val, value: val };
        } else {
          const modifiedLabel = val.label.startsWith("Add ") ? val.label.replace(/^Add\s+/i, "") : val.label;
          return { ...val, label: modifiedLabel };
        }
      });

    if (newOptions.length > 0) {
      setOptions(prevOptions => [...prevOptions, ...newOptions]);
    }

    const selectedValues = newValues.map(val => (typeof val === "string" ? val : val.value));

    onChange?.(selectedValues);
  };

  const handleChange = (event, value) => {
    if (multiple && Array.isArray(value)) {
      handleOnChangeMultiple(event, value);
    } else if (value && typeof value === "object") {
      handleOnChangeSingle(event, value);
    }
  };

  const icon = <CheckBoxOutlineBlank fontSize='small' />;
  const checkedIcon = <CheckBoxIcon fontSize='small' />;

  const iconElement = addIcon && (
    <BXIcon
      width={"25px"}
      height={"25px"}
      icon={props?.iconConfig?.icon}
      url={props?.iconConfig?.url}
      visibility={props?.iconConfig?.visibility}
      color={"#fff"}
      style={{ maxWidth: "100%", maxHeight: "100%" }}
    />
  );

  const selectedValues = useMemo(() => {
    if (multiple) {
      return Array.isArray(value) ? value.map(val => options.find(option => option.value === val)).filter(Boolean) : [];
    } else {
      return options?.find(option => option.value === value) || (value ? { label: value, value: value } : null);
    }
  }, [value, options, multiple]);

  return (
    <Autocomplete
      freeSolo={freeSolo}
      filterSelectedOptions={filterSelectedOptions}
      multiple={multiple}
      readOnly={readOnly}
      options={options}
      onInputChange={(event, newInputValue) => {
        if (config?.suggestionEndpoint?.suggestionSourceType == "API") {
          debouncedFetchRef.current(newInputValue);
        }
      }}
      onFocus={e => {
        e.preventDefault();
      }}
      value={selectedValues}
      getOptionLabel={option => (typeof option === "string" ? option : option.label)}
      onChange={handleChange}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {showCheckbox && <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />}
          {option.label}
        </li>
      )}
      renderInput={params => {
        let handleOnClick;
        if (React.isValidElement(params.InputProps.endAdornment)) {
          handleOnClick = params?.InputProps?.endAdornment?.props?.children[1]?.props?.onClick;
        }
        const inputPropsWithIcon = addIcon
          ? {
              ...params.InputProps,
              startAdornment: (
                <>
                  {!iconRight && iconElement}
                  {arrowIcon && !freeSolo && <ArrowDropDownIcon onClick={handleOnClick} sx={{ cursor: "pointer" }} />}
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {iconRight && iconElement}
                  {params.InputProps.endAdornment}
                </>
              ),
            }
          : arrowIcon && !freeSolo
          ? {
              ...params.InputProps,
              startAdornment: (
                <>
                  <ArrowDropDownIcon onClick={handleOnClick} sx={{ cursor: "pointer" }} />
                  {params.InputProps.startAdornment}
                </>
              ),
            }
          : params.InputProps;

        return <TextField {...params} label={placeholder} InputProps={inputPropsWithIcon} />;
      }}
      renderTags={(selectedOptions, getTagProps) => {
        if (selectedOptions.length > Number(numShowMore) && showMore) {
          const firstTwoOptions = selectedOptions.slice(0, Number(numShowMore));
          const moreCount = selectedOptions.length - Number(numShowMore);
          return [
            ...firstTwoOptions.map((option, index) => <Chip label={option.label} {...getTagProps({ index })} />),
            <Chip label={`${moreCount} more`} {...getTagProps({ index: Number(numShowMore) })} disabled />,
          ];
        } else {
          return selectedOptions.map((option, index) => <Chip label={option.label} {...getTagProps({ index })} />);
        }
      }}
      filterOptions={(options, state) => {
        const inputValue = state.inputValue.trim().toLowerCase();
        if (!inputValue) {
          return freeSolo && !listFreeSolo ? [] : options;
        }

        const filteredOptions = options.filter(option => option.label.toLowerCase().includes(inputValue));

        const validAdditionalData = additionalData?.current
          ?.filter(option => option && option.label)
          .map(option => ({
            label: option.label,
            value: option.value,
          }));

        const filteredAdditionalData = validAdditionalData?.filter(
          option =>
            option?.label?.toLowerCase().startsWith(inputValue) && !options?.some(existingOption => existingOption?.value === option?.value)
        );

        const isExisting = options?.some(option => inputValue === option.label.toLowerCase());

        if (inputValue !== "" && !isExisting && addWord && freeSolo) {
          filteredOptions.push({
            label: `Add ${inputValue}`,
            value: inputValue,
          });
        }
        if (config?.suggestionEndpoint?.suggestionSourceType == "SIMPLE") {
          return [...filteredOptions, ...filteredAdditionalData];
        } else if (config?.suggestionEndpoint?.suggestionSourceType == "API") {
          return [...filteredOptions, ...filteredAdditionalDataApi];
        } else {
          return [...filteredOptions];
        }
      }}
      componentsProps={{
        popupIndicator: {
          sx: {
            ...(arrowIcon && { display: "none" }),
          },
        },
      }}
    />
  );
};
