/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Grid, Paper, Switch, TextField, Typography } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { BXMapValues } from "./BXMapValues";
import { ChainActionsConfig } from "./ChainActionsConfig";

export const MapChainActions = ({
  item,
  view,
  views,
  appId,
  collectionId,
  pageId,
  handleConfigChange,
  onSave,
  onChangeProp,
  isTable = false,
  prefixTableActionPath,
}) => {
  //Actions Map Variables
  let { isChainMapped, actionsKey, isLoadingForEntireChain, conditionActionMapKey } = item?.config?.actionMappingConfig || {
    isChainMapped: false,
    actionsKey: "",
    isLoadingForEntireChain: true,
  };
  let { actionsMap } = item || {};

  //Properties of table actions
  const { showButtonLabel, enableSelectedRows, enableCSV, visibilityCondition, isGlobal } = prefixTableActionPath;

  //Override if the actions are table actions
  if (isTable) {
    isChainMapped = prefixTableActionPath?.isChainMapped;
    actionsKey = prefixTableActionPath?.actionsKey;
    isLoadingForEntireChain = prefixTableActionPath?.actionMappingConfig?.isLoadingForEntireChain;
    actionsMap = prefixTableActionPath?.actionsMap;
    conditionActionMapKey = prefixTableActionPath.conditionActionMapKey;
    //Other table action properties
  }

  const [selectedValue, setSelectedValue] = useState<string>("");

  useEffect(() => {
    //If item has no actions map, configure a new one and insert old action on it, for both form builder and table
    if (!isTable) {
      if (!item?.actionsMap) {
        const newItem = {
          ...item,
          actionsMap: {
            default: item?.actionConfig ? [{ actionConfig: { ...item?.actionConfig } }] : [],
          },
        };

        setSelectedValue("default");
        onChangeProp?.(newItem);
      }
    } else {
      //For table
      if (_.isEmpty(actionsMap)) {
        //Add previous actions of table
        const initialMap = {
          default: prefixTableActionPath.actionSourceType ? [{ actionConfig: prefixTableActionPath }] : [],
        };

        setSelectedValue("default");
        onChangeProp?.(initialMap);
      }
    }
    setSelectedValue("default");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentActions = [...(actionsMap?.[selectedValue] || [])];

  const onDeleteAction = index => {
    const updatedActions = [...(actionsMap?.[selectedValue] || [])];
    updatedActions.splice(index, 1);

    const newItem = {
      ...item,
      actionsMap: {
        ...item?.actionsMap,
        [selectedValue]: updatedActions,
      },
    };

    const updatedTableActionsMap = {
      ...actionsMap,
      ...newItem.actionsMap,
    };

    isTable ? onChangeProp?.(updatedTableActionsMap) : onChangeProp?.(newItem);
  };

  function addNewChain(value) {
    setSelectedValue(value);
    const newItem = {
      ...item,
      actionsMap: {
        ...item?.actionsMap,
        [value]: [],
      },
    };

    const updatedTableActionsMap = {
      ...actionsMap,
      ...newItem.actionsMap,
    };

    isTable ? onChangeProp?.(updatedTableActionsMap) : onChangeProp?.(newItem);
  }

  function copyFromChain(value, source) {
    const newItem = {
      ...item,
      actionsMap: {
        ...item?.actionsMap,
        [value]: [...(source?.actions as [])],
      },
    };

    const updatedActionsMap = newItem.actionsMap;

    isTable ? onChangeProp?.(updatedActionsMap) : onChangeProp?.(newItem);
  }

  function copyFromDefault(value) {
    const defaultActions = item?.actionsMap?.default || [];
    const newItem = {
      ...item,
      actionsMap: {
        ...item?.actionsMap,
        [value]: [...defaultActions],
      },
    };

    const updatedTableActionsMap = {
      ...actionsMap,
      ...newItem.actionsMap,
    };

    isTable ? onChangeProp?.(updatedTableActionsMap) : onChangeProp?.(newItem);
    setSelectedValue(value);
  }

  function deleteChain(value) {
    const updatedActionsMap = { ...actionsMap };
    delete updatedActionsMap[value];

    isTable
      ? onChangeProp?.(updatedActionsMap)
      : onChangeProp?.({
          ...item,
          actionsMap: updatedActionsMap,
        });
    setSelectedValue("default");
  }
  const [condition, setCondition] = useState((isTable ? conditionActionMapKey : item?.config?.conditionActionMapKey) || "equal");
  const handleConditionChange = (newCondition: string) => {
    setCondition(newCondition);

    if (isTable) {
      handleConfigChange?.("conditionActionMapKey", newCondition);
      return;
    }

    const newItem = {
      ...item,
      config: {
        ...item?.config,
        conditionActionMapKey: newCondition,
      },
    };
    onChangeProp?.(newItem);
  };

  return (
    <>
      <Grid container display={"flex"} flexDirection={"column"} spacing={1}>
        <Grid item xs={12} display={"flex"} justifyContent={"start"} alignItems={"center"}>
          <Typography sx={{ fontSize: "17px" }}>Map values</Typography>
          <Switch
            size='medium'
            checked={!!isChainMapped}
            onChange={e => {
              if (!isTable) {
                handleConfigChange("actionMappingConfig.isChainMapped", "boolean")(e);
              } else {
                handleConfigChange("isChainMapped", e?.target?.checked);
              }
              if (!e.target.checked) {
                setSelectedValue("default");
              }
            }}
            inputProps={{ "aria-label": "map-chains" }}
          />
        </Grid>

        <Grid item xs={12} display={"flex"} justifyContent={"start"} alignItems={"center"}>
          {!!isChainMapped && (
            <BXMapValues
              initialKey={actionsKey}
              targetMap={actionsMap}
              queryString='actionMappingConfig.actionsKey'
              handleKeyChange={handleConfigChange}
              setSelectedValue={setSelectedValue}
              addNewValue={addNewChain}
              copyFromDefault={copyFromDefault}
              copyFromValue={copyFromChain}
              deleteValue={deleteChain}
              condition={condition}
              setCondition={setCondition}
              handleConditionChange={handleConditionChange}
              isTable={isTable}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <ChainActionsConfig
            key={selectedValue}
            selectedValue={selectedValue}
            isLoadingForEntireChain={isLoadingForEntireChain}
            onLoadingChange={e => {
              if (!isTable) {
                handleConfigChange(`actionMappingConfig.isLoadingForEntireChain.[${selectedValue}]`, "boolean")(e);
              } else {
                handleConfigChange(`actionMappingConfig.isLoadingForEntireChain.[${selectedValue}]`, e?.target?.checked);
              }
            }}
            views={views}
            view={view}
            item={item}
            appId={appId}
            collectionId={collectionId}
            pageId={pageId}
            onDeleteAction={onDeleteAction}
            onSave={newChain => {
              if (!isTable) {
                const newItem = {
                  ...item,
                  actionsMap: {
                    ...item.actionsMap,
                    [selectedValue]: [...newChain],
                  },
                  interactionConfig: item?.interactionConfig || [
                    {
                      type: "OnClick",
                      triggerSelf: true,
                      actionableKey: "",
                      key: "Enter",
                    },
                  ],
                };

                onChangeProp?.(newItem);
              } else {
                const newActionsMap = {
                  ...actionsMap,
                  [selectedValue]: [...newChain],
                };

                onChangeProp?.(newActionsMap);
              }
            }}
            actions={currentActions}
          />
        </Grid>

        {/* Table Properties UI */}
        {isTable && (
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ padding: 2, borderRadius: 2 }}>
              <Grid container spacing={1} alignItems='center'>
                {/* Section for Show Button Label */}
                <Grid item xs={12} sm={3} display={"flex"} justifyContent={"start"} alignItems={"center"}>
                  <Typography variant='subtitle1'>Show Button Label</Typography>
                  <Switch
                    size='medium'
                    checked={!!showButtonLabel}
                    onChange={e => handleConfigChange("showButtonLabel", e.target.checked)}
                  />
                </Grid>

                {/* Section for Enable Selected Rows */}
                <Grid item xs={12} sm={4} display={"flex"} justifyContent={"start"} alignItems={"center"}>
                  <Typography variant='subtitle1'>Enable for Selected Rows</Typography>
                  <Switch
                    size='medium'
                    checked={!!enableSelectedRows}
                    onChange={e => handleConfigChange("enableSelectedRows", e.target.checked)}
                  />
                </Grid>

                {/* Section for Enable CSV */}
                <Grid item xs={12} sm={4} display={"flex"} justifyContent={"start"} alignItems={"center"}>
                  <Typography variant='subtitle1'>Enable CSV</Typography>
                  <Switch size='medium' checked={!!enableCSV} onChange={e => handleConfigChange("enableCSV", e.target.checked)} />
                </Grid>
              </Grid>

              {/* Section for Visibility Condition */}
              <Grid container spacing={1} sx={{ mt: 2 }}>
                <Grid item xs={6}>
                  <TextField
                    size='medium'
                    fullWidth
                    label='Visibility Condition'
                    variant='outlined'
                    value={visibilityCondition || ""}
                    onChange={e => {
                      handleConfigChange("visibilityCondition", e.target.value);
                    }}
                  />
                </Grid>
              </Grid>

              {/* Section for Show in Action Header or Each Row */}
              <Grid container spacing={1} alignItems='center' sx={{ mt: 2 }}>
                <Grid item xs={12} display={"flex"} justifyContent={"start"} alignItems={"center"}>
                  <Typography variant='subtitle1'>{isGlobal ? "Show in Action Header" : "Show in Each Row"}</Typography>
                  <Switch
                    size='medium'
                    checked={!!isGlobal}
                    onChange={e => {
                      handleConfigChange("isGlobal", e.target.checked);
                      // handleConfigChange("isItem", !e.target.checked);
                    }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}

        <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Button variant={"outlined"} onClick={onSave}>
            Done
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
