import { Typography as MUITypography } from "@mui/material";
import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { decodeMarkdown, formatDate } from "src/utils/generalUtils";
import { TypographyProps } from "./types";

const convertText = (text: string, linkColor?: string, underlineLink?: boolean) => {
  const cleanedText = text.replace(/\\"/g, '"');

  const linkStyle: React.CSSProperties = {
    color: linkColor || "inherit",
    textDecoration: underlineLink ? "underline" : "none",
  };

  const newlineParts = cleanedText.split(/\\n|\r?\n/);
  const result: React.ReactNode[] = [];

  newlineParts.forEach((part, index) => {
    // Regex for <a> tags and <b> tags
    const anchorTagRegex = /<a\s+href\s*=\s*"(.*?)"\s*>(.*?)<\/a>/gi;
    const boldTagRegex = /<b>(.*?)<\/b>/gi;

    let lastIndex = 0;
    let match: RegExpExecArray | null;

    // Process anchor tags first
    while ((match = anchorTagRegex.exec(part)) !== null) {
      const textBefore = part.slice(lastIndex, match.index);
      if (textBefore) result.push(textBefore);

      const href = match[1];
      const anchorText = match[2];
      result.push(
        <a key={`anchor-${index}-${match.index}`} href={href.trim()} target='_blank' style={linkStyle} rel='noreferrer'>
          {anchorText.trim()}
        </a>
      );

      lastIndex = match.index + match[0].length;
    }

    // Process bold tags within the remaining text
    const remainingTextAfterAnchors = part.slice(lastIndex);
    let boldLastIndex = 0;

    while ((match = boldTagRegex.exec(remainingTextAfterAnchors)) !== null) {
      const textBeforeBold = remainingTextAfterAnchors.slice(boldLastIndex, match.index);
      if (textBeforeBold) result.push(textBeforeBold);

      const boldText = match[1];
      result.push(<strong key={`bold-${index}-${match.index}`}>{boldText}</strong>);

      boldLastIndex = match.index + match[0].length;
    }

    const remainingTextAfterBold = remainingTextAfterAnchors.slice(boldLastIndex);
    if (remainingTextAfterBold) result.push(remainingTextAfterBold);

    // Add line break if not the last part
    if (index < newlineParts.length - 1) {
      result.push(<br key={`br-${index}`} />);
    }
  });

  return result;
};

export const Typography: FC<TypographyProps> = ({ children, format, formatString, config, __config, metaData, ...restProps }) => {
  const location = useLocation();
  const isInBuilder = location.pathname.startsWith("/buildx/form-builder/");
  const { isUserLocalTime, underlineLink, linkColor } = config || {};
  const isObject = typeof children === "object";

  const prettifyJSON = jsonString => {
    try {
      const formattedJSON = formatJSON(jsonString);
      return formattedJSON !== null ? <pre>{formattedJSON}</pre> : jsonString;
    } catch (error) {
      console.error("Error prettifying JSON:", error);
      return jsonString;
    }
  };

  let content = isObject ? prettifyJSON(JSON.stringify(children)) : children;

  if (typeof content === "string") {
    content = decodeMarkdown(content);
    content = convertText(content, linkColor, underlineLink);
  }

  const style = isObject ? { overflow: "auto", maxHeight: "300px" } : {};

  if (format === "Date Time") {
    content = formatDate(content, formatString, isInBuilder ? content : "", isUserLocalTime);
  }

  return (
    <MUITypography {...restProps} style={style}>
      {content}
    </MUITypography>
  );
};
