import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";

import useTheme from "@mui/material/styles/useTheme";
import { IconChartArea, IconList, IconSelect, IconTable } from "@tabler/icons-react";
import _ from "lodash";
import { memo, useEffect, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import axios from "src/utils/axios";
import { ReactComponent as ExpandMoreIcon } from "../../../../assets/images/icons/ExpandMoreIcon.svg";
import ComponentsTree from "./BuilderComponentsTree/ComponentsTree";
import { DragElement } from "./DragElement";
import { a11yProps, TabPanel } from "./RightSideMenuComponent";
import { chartsNames, components } from "./utils";

const categories = [
  {
    title: "Container",
    group: "container",
  },
  {
    title: "Inputs",
    group: "inputs",
  },
  {
    title: "Media",
    group: "media",
  },
  {
    title: "Buttons",
    group: "buttons",
  },
  {
    title: "Charts",
    group: "charts",
  },
  {
    title: "Stripe Elements",
    group: "stripeElements",
  },
];

const ViewPicker = ({ onViewSelect, options }) => {
  const [pickerOpen, setPickerOpen] = useState<boolean>();
  const [searchText, setSearchText] = useState<string | null>(null);
  return (
    <Autocomplete
      size={"small"}
      // sx={{ width: 300 }}
      open={pickerOpen}
      onOpen={() => {
        setPickerOpen(true);
      }}
      onClose={() => {
        setPickerOpen(false);
      }}
      onChange={(e, newValue) => {
        if (newValue) {
          onViewSelect?.(newValue);
        }
      }}
      isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
      getOptionLabel={option => option.label}
      options={options.filter(option => (searchText ? option?.label?.includes?.(searchText) || [] : []))}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      componentsProps={{
        clearIndicator: {
          sx: {
            marginInlineEnd: 0.3,
          },
        },
      }}
      renderInput={params => (
        <TextField
          {...params}
          label='Views'
          onChange={e => setSearchText(e.target.value)}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

const SideMenuComponent = memo((props: any) => {
  const { item, appBuilderMode } = props;
  const theme = useTheme();
  if (!appBuilderMode && (item.type == "BXSideBar" || item.type == "BXNavBar")) {
    return null;
  }

  return (
    <>
      <Paper elevation={3} style={{ padding: "8px", marginBottom: "4px", width: "100%", boxShadow: "none" }}>
        <DragElement isSideMenu={true} resizable={false} {...props}>
          <Grid container alignItems='center'>
            <Grid item>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.default,
                  border: `2px solid ${theme.palette.primary[200]}`,
                  borderRadius: ".5vh",
                  width: "48px",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginInlineEnd: "8px",
                }}
              >
                <img src={item?.config?.placeholderConfig?.image} alt={item?.config?.placeholderConfig?.title} />
              </Box>
            </Grid>
            <Grid item xs>
              <Typography
                variant='h6'
                sx={{
                  color: theme.palette.primary[200],
                }}
              >
                {item?.config?.placeholderConfig?.title}
              </Typography>
            </Grid>
          </Grid>
        </DragElement>
      </Paper>
    </>
  );
});

export const LeftSideMenuItems = memo(
  ({
    layoutBreak,
    onCustomComponentSelect,
    onViewSelect,
    views,
    isRTL,
    appBuilderMode,
    pageBuilderMode,
    handleChangeLeftTab,
    leftTabValue,
    activeComponent,
    setView,
    elements,
    handleDeleteItem,
    handleSelectComponent,
    handleCopyItems,
    handlePasteItems,
    view,
    onDiscardEdit,
    selectedItemsId,
    setSelectedItemsId,
    openStates,
    setOpenStates,
    onChangePropConfigTreeMenu,
  }: any) => {
    const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [expandedCategories, setExpandedCategories] = useState<string[]>([]);
    const componentsListRef = useRef<HTMLElement>(null);

    const theme = useTheme();

    let queryKey = ["component-list"];
    if (searchText.trim()) {
      queryKey.push(searchText.trim());
    }
    const {
      data: options,
      isFetching,
      fetchNextPage,
      hasNextPage,
      isFetchingNextPage,
    } = useInfiniteQuery(
      queryKey,
      ({ pageParam }) => {
        return axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/component", {
          params: {
            cursor: pageParam,
            keyword: searchText || undefined,
          },
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        });
      },
      {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        getNextPageParam: lastPage => (lastPage?.data?.hasMore ? lastPage?.data?.cursor : undefined),
        enabled: !!autoCompleteOpen,
      }
    );
    const loading = autoCompleteOpen && isFetching;

    const debouncedHandleScroll = _.debounce(() => {
      const container = componentsListRef.current;
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        if (scrollTop + clientHeight >= scrollHeight - 20 && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      }
    }, 100);

    useEffect(() => {
      const container = componentsListRef.current;
      if (!container) return;

      container.addEventListener("scroll", debouncedHandleScroll);
      return () => {
        container.removeEventListener("scroll", debouncedHandleScroll);
      };
    }, [debouncedHandleScroll]);

    const handleAccordionChange = (group: string) => {
      if (expandedCategories.includes(group)) {
        setExpandedCategories(expandedCategories.filter(item => item !== group));
      } else {
        setExpandedCategories([...expandedCategories, group]);
      }
    };

    return (
      <Box sx={{ backgroundColor: (theme: Theme) => theme.palette.background.paper, borderRadius: "10px", width: "100%", height: "100%" }}>
        <Box sx={{ position: "sticky", top: "100px", zIndex: "1000", maxHeight: "calc(100vh - 100px)", overflow: "auto" }}>
          <Tabs
            scrollButtons='auto'
            variant='scrollable'
            value={leftTabValue}
            onChange={handleChangeLeftTab}
            aria-label='basic tabs example'
          >
            <Tab sx={{ textTransform: "none", fontSize: "12px", textAlign: "center" }} label='Components' {...a11yProps(0)} />
            <Tab sx={{ textTransform: "none", fontSize: "12px", textAlign: "center" }} label='Outline' {...a11yProps(1)} />
          </Tabs>

          <Box sx={{ overflowX: "hidden" }}>
            <TabPanel value={leftTabValue} index={0}>
              {categories.map((category, index) => (
                <Accordion
                  disableGutters
                  elevation={0}
                  key={index}
                  expanded={expandedCategories.includes(category.group)}
                  onChange={() => handleAccordionChange(category.group)}
                  sx={{
                    marginBottom: 0,
                    borderTop: `1px solid ${theme.palette.background.default}`,
                    borderBottom: `1px solid ${theme.palette.background.default}`,
                    borderLeft: "none",
                    borderRight: "none",
                    "&:before": {
                      display: "none",
                    },
                    "&:not(:last-child)": {
                      borderBottom: "none",
                    },
                    "&:first-child": {
                      borderTop: "none",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        style={{ transform: expandedCategories.includes(category.group) ? "rotate(270deg)" : "rotate(0deg)" }}
                      />
                    }
                    aria-controls={`category-${index}-content`}
                    id={`category-${index}-header`}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "row-reverse",
                      margin: "0 !important",
                      padding: "0 8px !important",
                      position: "relative",
                    }}
                  >
                    <Typography
                      variant='h5'
                      sx={{
                        userSelect: "none",
                        color: theme.palette.primary[200],
                      }}
                    >
                      {category.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: "0 8px !important",
                    }}
                  >
                    {!!components?.length &&
                      components
                        .filter((item: any) => item?.config?.placeholderConfig?.group == category.group)
                        .map((filteredItem, index) => {
                          return (
                            <SideMenuComponent
                              key={index}
                              item={filteredItem}
                              index={index}
                              hideSource={false}
                              layoutBreak={layoutBreak}
                              isRTL={isRTL}
                              appBuilderMode={appBuilderMode}
                              views={views}
                            />
                          );
                        })}
                  </AccordionDetails>
                </Accordion>
              ))}

              <Grid container alignItems='center'>
                <Grid item xs={12} fontWeight={700} mt={2} mx={2}>
                  <Typography>Custom Components</Typography>
                </Grid>

                <Box mt={1.5}>
                  <Divider />
                </Box>

                <Grid item xs={12} mt={2} mx={2}>
                  <Autocomplete
                    open={autoCompleteOpen}
                    onOpen={() => {
                      setAutoCompleteOpen(true);
                    }}
                    onClose={() => {
                      setAutoCompleteOpen(false);
                    }}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        onCustomComponentSelect?.(newValue, 300, 400);
                      }
                    }}
                    isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
                    getOptionLabel={option => option.name}
                    options={_.flatMap(options?.pages, page => page?.data?.items) || []}
                    renderOption={(props, option) => (
                      <li {...props}>
                        {option?.config?.type === "autoComplete" && (
                          <IconList style={{ marginInlineEnd: 5, minWidth: "20px", maxWidth: "20px" }} />
                        )}
                        {option?.config?.type === "formTable" && (
                          <IconTable style={{ marginInlineEnd: 5, minWidth: "20px", maxWidth: "20px" }} />
                        )}
                        {option?.config?.type === "Select" && (
                          <IconSelect style={{ marginInlineEnd: 5, minWidth: "20px", maxWidth: "20px" }} />
                        )}
                        {chartsNames.includes(option?.config?.type) && (
                          <IconChartArea style={{ marginInlineEnd: 5, minWidth: "20px", maxWidth: "20px" }} />
                        )}
                        <Tooltip title={option.name}>
                          <Typography
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: 250,
                            }}
                          >
                            {option.name}
                          </Typography>
                        </Tooltip>
                      </li>
                    )}
                    loading={loading}
                    componentsProps={{
                      clearIndicator: {
                        sx: {
                          marginInlineEnd: 0.3,
                        },
                      },
                    }}
                    ListboxProps={{
                      ref: componentsListRef,
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        size='small'
                        label='Add Component'
                        onChange={e => setSearchText(e.target.value)}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{loading ? <CircularProgress color='inherit' size={20} /> : null}</>,
                        }}
                        sx={{
                          marginBottom: "20px",
                        }}
                      />
                    )}
                  />
                </Grid>
                {pageBuilderMode && (
                  <Grid container alignItems='center'>
                    <Grid item xs={12} fontWeight={700} mt={2} mx={2}>
                      <Typography>Views</Typography>
                    </Grid>

                    <Box mt={1.5}>
                      <Divider />
                    </Box>

                    <Grid item xs={12} mt={2} mx={2}>
                      <ViewPicker options={views?.map(v => ({ label: v.info.name, value: v.id })) || []} onViewSelect={onViewSelect} />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value={leftTabValue} index={1}>
              <Grid container px={1} py={2}>
                <Grid item xs={12}>
                  <Box display='flex' justifyContent='space-between' alignItems='center' pb={3}>
                    <ComponentsTree
                      activeComponent={activeComponent}
                      children={elements}
                      view={view}
                      setView={setView}
                      handleDeleteItem={handleDeleteItem}
                      handleSelectComponent={handleSelectComponent}
                      handleCopyItems={handleCopyItems}
                      handlePasteItems={handlePasteItems}
                      onDiscardEdit={onDiscardEdit}
                      selectedItemsId={selectedItemsId}
                      setSelectedItemsId={setSelectedItemsId}
                      openStates={openStates}
                      setOpenStates={setOpenStates}
                      onChangePropConfigTreeMenu={onChangePropConfigTreeMenu}
                    ></ComponentsTree>
                  </Box>
                </Grid>
              </Grid>
            </TabPanel>
          </Box>
        </Box>
      </Box>
    );
  }
);
